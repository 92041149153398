import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import WarningIcon from "@material-ui/icons/ErrorOutline";

type WarningInfoType = {
  children: React.ReactNode
};

const useStyles = makeStyles(() => ({
  warning: {
    color: '#663c00',
    backgroundColor: '#fff4e5',
    alignItems: 'center',
    display: 'flex',
    padding: '5px',
    "& svg": {
      fontSize: 30,
      padding: '0 5px'
    }
  }
}));

export function WarningInfo({ children }: WarningInfoType) {
  const classes = useStyles();

  return (
    <div className={classes.warning}>
      <WarningIcon />
      {children}
    </div>
  )
}


