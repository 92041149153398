import { StepType } from "../../components/WizardStep/WizardStep";
import { STAGE, SPECIAL_COMPONENTS } from "./WizardEnums";

export enum FreightSteps {
  FRAME_SEND = "FRAME_SEND",
  PLATES_SEND = "PLATES_SEND" 
}

export enum FrameStages {
  PICTURE_FRAME = "PICTURE_FRAME"
}


export const FreightWizardQuestions: StepType[] = [
  {
    id: FreightSteps.FRAME_SEND,
    label: "Frame send",
    stages: [
      {
        id: FrameStages.PICTURE_FRAME,
        type: STAGE.FILE,
        label: "Picture of the frame on transport truck",
        uploadFilesLabel: "Upload Picture"
      }
    ]
  },
  {
    id: FreightSteps.PLATES_SEND,
    label: "Plates send",
    stages: [],
    specialComponent: SPECIAL_COMPONENTS.PlatesCheckbox
  }
];
