import React from 'react';
import { makeStyles } from '@material-ui/styles';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { ThemeProvider } from '@material-ui/styles';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import primaryColor from '@material-ui/core/colors/teal';
import secondaryColor from '@material-ui/core/colors/deepOrange';
import CssBaseline from '@material-ui/core/CssBaseline';

import AppToolbar from './AppBar';
import { LockOrientationContext } from './LockOrientation';

const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: primaryColor,
    secondary: secondaryColor
  },
  mixins: {
    toolbar: {
      minHeight: 70
    }
  }
});

export const SearchContext = React.createContext({});

export function withSearchContext(Component) {
  return function SearchContextComponent(props) {
    return (
      <SearchContext.Consumer>
        {function (values) {
          return <Component {...props} {...values} />;
        }}
      </SearchContext.Consumer>
    );
  };
}

const Layout = ({ children }) => {
  const [searchOpen, setSearch] = React.useState(false);
  const [isDesktopDevice, setIsDesktopDevice] = React.useState(false);
  const [isMediumDevice, setIsMediumDevice] = React.useState(false);

  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <SearchContext.Provider value={{ searchOpen, setSearch }}>
            <LockOrientationContext.Provider
              value={{
                isDesktopDevice,
                setIsDesktopDevice,
                isMediumDevice,
                setIsMediumDevice
              }}
            >
              <CssBaseline />
              <AppToolbar searchOpen={searchOpen} setSearch={setSearch} />
              <LayoutWrapper>
                {children}
              </LayoutWrapper>
            </LockOrientationContext.Provider>
          </SearchContext.Provider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </MuiThemeProvider>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.mixins.toolbar.minHeight + theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    flex: '1 1 100%',

    [theme.breakpoints.up('sm')]: {
      height: '100%',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    }
  }
}));

const LayoutWrapper = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {children}
    </div>
  );
};

export default Layout;

