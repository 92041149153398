import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select, Theme } from '@material-ui/core';
import ReactDOM from "react-dom";

type InlineDeliveryMethodType = {
  value: string,
  onChange: (evt: any) => void,
  dictionary: DictionaryType[]
};

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

export function InlineDeliveryMethod({ dictionary, value, onChange }: InlineDeliveryMethodType) {
  const classes = useStyles();
  const [labelWidth, setLabelWidth] = React.useState(0);
  const labelRef = React.useRef(null);

  React.useEffect(() => {
    setLabelWidth((ReactDOM.findDOMNode(labelRef.current) as HTMLElement)!.offsetWidth);
  }, []);

  const hasError = !value;
  return (
    <FormControl variant="outlined" fullWidth className={classes.formControl} error={hasError}>
      <InputLabel ref={labelRef} htmlFor="delivery-method-input" error={hasError}>
        Packing Method
      </InputLabel>
      <Select
        value={value}
        onChange={onChange}
        variant="outlined"
        fullWidth
        displayEmpty
        error={hasError}
        input={
          <OutlinedInput
            labelWidth={labelWidth}
            name="delivery-method"
            id="delivery-method-input"
            error={hasError}
          />
        }
      >
        {dictionary.map(({ text, value }: DictionaryType) => (
          <MenuItem key={value} value={value}>
            {text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
