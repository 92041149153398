import classnames from "classnames";
import React from "react";
import { connect } from "react-redux";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";
import { PreventPropagation } from "../../components/PreventPropagation";

import { setDeliveryMethod } from "../../redux/actions/ordersActions";
import { setPlateDeliveryMethod } from "../../redux/actions/plateActions";

const styles = theme => ({
  select: { maxWidth: 200 },
  input: { padding: theme.spacing(0.8) },
  smallInput: { padding: theme.spacing(0.5) },
});

export class PlateDeliveryMethodInlineEdit extends React.PureComponent {
  handleDeliveryMethodChange = (evt) => {
    if (this.props.plateId) {
      this.props.setPlateDeliveryMethod(this.props.plateId, evt.target.value);
    } else {
      this.props.setDeliveryMethod(this.props.orderId, evt.target.value);
    }
  };

  render() {
    const inputClasses = classnames({
      [this.props.classes.select]: true,
      [this.props.classes.input]: !this.props.small,
      [this.props.classes.smallInput]: this.props.small
    });

    const { deliveryMethodsDictionary, deliveryMethod, classes, disabled } = this.props;
    return (
      <PreventPropagation>
        <FormControl variant="outlined" fullWidth>
          <Select
            disabled={disabled}
            value={deliveryMethod || ''}
            onChange={this.handleDeliveryMethodChange}
            variant="outlined"
            fullWidth
            input={
              <OutlinedInput
                labelWidth={0}
                name="delivery-method"
                id="delivery-method-input"
                classes={{ input: inputClasses }}
              />
            }
            className={classes.select}
          >
            {deliveryMethodsDictionary.map(({ text, value }) => (
              <MenuItem key={value} value={value}>
                {text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </PreventPropagation>
    );
  }
}

const mapStateToProps = (state) => ({ deliveryMethodsDictionary: state.dictionary.deliveryMethodsDictionary });
PlateDeliveryMethodInlineEdit = withStyles(styles)(connect(mapStateToProps, {
  setDeliveryMethod,
  setPlateDeliveryMethod
})(PlateDeliveryMethodInlineEdit));
