import { STAGE, SPECIAL_COMPONENTS } from "../../shared/WizardStep/WizardEnums";
import { StepType } from "./WizardStep";
import * as React from "react";
import { useSelector } from "react-redux";
import { getValueKey } from "../../shared/WizardStep/wizardHelpers";
import { StageType } from "../../shared/WizardStep/WizardTypes";
import { WizardStateEnum } from "../../shared/WizardStep/WizardEnums";

type Props = {
  stepList: Array<StepType>;
  orderId: number;
  specHasError?: { [key: string]: boolean };
}

const useGetStepStatuses = (orderId: number, approveDate: Date, referenceNumber: string) => {
  const { stepStatuses = {} } = useSelector((state: GlobalStoreType) => {
    if (approveDate) {
      return state.orders.entities[orderId as any];
    }

    const offerId = Number(referenceNumber);
    return state.orders.newOrdersInfoEntities[offerId];
  });

  if (!stepStatuses) {
    return {};
  }

  return stepStatuses
}

export const useWizardStepHandler = ({ stepList, orderId, specHasError }: Props) => {
  const [currentStepListInformation, setCurrentStepListInformation] = React.useState(stepList)
  const activeStepIndex = currentStepListInformation.findIndex((e: StepType) => e.state !== WizardStateEnum.DISABLED);
  const [activeStep, setActiveStep] = React.useState(currentStepListInformation[activeStepIndex].id);
  const {
    reference_number,
    approve_date,
    files = []
  } = useSelector((state: GlobalStoreType) => state.orders.entities[orderId as any]);
  const [currentValues, setCurrentValues] = React.useState(useGetStepStatuses(orderId, approve_date, reference_number));

  const currentStep = React.useMemo(() => currentStepListInformation.find(e => e.id === activeStep), [activeStep]);

  const isStageCompleted = (stepId: string, stage: StageType) => {
    const key = getValueKey(stepId, stage.id);
    const isOptionalFile = stage.uploadFilesOptional ? stage.uploadFilesOptional(key, currentValues[key], files) : false;
    const array = currentValues[key];
    const value = currentValues[key];

    if (stage.type === STAGE.BOOL) {
      return value !== undefined;
    }

    if (stage.type === STAGE.FILE) {
      const filesNoRequired = (value === false);
      return isOptionalFile || filesNoRequired || files.some(e => e.file_type === key)
    }

    if (stage.uploadFiles && !isOptionalFile) {
      let isFilesMissing = false;

      if (stage.multiple && Array.isArray(array)) {
        isFilesMissing = array.some((_item: any, index: number) => {
          return !files.some((file: any) => file.file_type === key+index);
        });
      } else {
        isFilesMissing = !files.some(e => e.file_type?.includes(key));
      }

      if (isFilesMissing) {
        return false;
      }
    }

    if (stage.type === STAGE.TEXT) {
      if (Array.isArray(array) && array.length > 0) {      
        if (stage.affectPrice) {
          return array.every((item: any) => item.value && item.hasOwnProperty('affectPrice') && (!item.affectPrice || item.affectPriceOption));
        } else {
          return array.every(e => e.value);
        }
      }

      return false;
    }

    return true;
  }

  const allStepCompleted = () => {
    setCurrentStepListInformation(value => value.map(step => {
      if (step.state === WizardStateEnum.DISABLED) {
        return step;
      }

      const filterOutSteps = getChoicePath[step.id];
      let isError = true;

      if (step.specialComponent) {
        isError = specHasError?.[step.specialComponent] || false;
        const errorState = (step.specialComponent === SPECIAL_COMPONENTS.CutoutsEdit) ? WizardStateEnum.UNSET : WizardStateEnum.NOT_DONE;
        
        return { 
          ...step, state: isError ? errorState : WizardStateEnum.COMPLETED 
        }
      }

      isError = step.stages
        .filter(stage => filterOutSteps.includes(stage.id))
        .some(stage => !isStageCompleted(step.id, stage))

      return {
        ...step, state: isError ? WizardStateEnum.NOT_DONE : WizardStateEnum.COMPLETED
      }
    }))
  }

  const isStepCompleted = (step = currentStep) => {
    if (!step) {
      return;
    }

    if (step.state === WizardStateEnum.DISABLED) {
      return;
    }

    let isError = true;
    if (step.specialComponent) {
      isError = specHasError?.[step.specialComponent] || false;
    } else {
      const filterOutSteps = getChoicePath[step.id];
      isError = step.specialComponent ? true : step.stages
        .filter(stage => filterOutSteps.includes(stage.id))
        .some(stage => !isStageCompleted(step.id, stage))
    }

    setCurrentStepListInformation(val => val.map((step) => {
      if (step.id === activeStep) {
        const errorState = (step.specialComponent === SPECIAL_COMPONENTS.CutoutsEdit) ? WizardStateEnum.UNSET : WizardStateEnum.NOT_DONE;
        
        return { ...step, state: isError 
          ? errorState
          : WizardStateEnum.COMPLETED };
      }

      return step;
    }));
  }

  const handleStep = (step: string) => () => {
    setActiveStep(step);
  };

  const getChoicePath = React.useMemo(() => {
    const stepsPath: { [key: string]: Array<string> } = {}
    currentStepListInformation.forEach((step) => {
      let currentStage: StageType | null = step.stages[0];
      if (!currentStage) {
        return
      }


      stepsPath[step.id as keyof typeof stepsPath] = [currentStage.id]

      while (currentStage) {
        const value = currentValues[getValueKey(step.id, currentStage.id)];

        let nextStageId: any = '';
        if (currentStage.type === STAGE.BOOL && value !== undefined) {
          nextStageId = value ? currentStage.isTrue : currentStage.isFalse;

          if (typeof nextStageId === "function") {
            nextStageId = nextStageId(currentValues, step.id)
          }
        }


        const isOptionalFile = currentStage.uploadFilesOptional ? currentStage.uploadFilesOptional() : false;
        if (currentStage.type === STAGE.TEXT && value && Array.isArray(value) && value.some(e => e)) {
          if (currentStage.uploadFiles) {
            if (files.some(f => f.file_type?.includes(getValueKey(step.id, currentStage?.id || ''))) || isOptionalFile) {
              nextStageId = currentStage.isConfirm;
            }
          } else {
            nextStageId = currentStage.isConfirm;
          }
        }

        if (currentStage.type === STAGE.FILE && files.some(f => f.file_type === getValueKey(step.id, currentStage?.id || '')) || isOptionalFile) {
          nextStageId = currentStage.isConfirm;
        }

        if (currentStage.alwaysShow) {
          nextStageId = currentStage.isConfirm;
        }

        const nextStage = step.stages.find((step) => step.id === nextStageId);
        if (nextStage) {
          stepsPath[step.id].push(nextStage.id);
          currentStage = nextStage;
        } else {
          currentStage = null;
        }
      }
    });

    return stepsPath;
  }, [currentValues, currentStepListInformation])

  const getFilteredList = React.useMemo(() => {
    const stepId = currentStep?.id
    if (stepId) {
      const filterOutSteps = getChoicePath[stepId];
      return currentStep?.stages
        .filter(stage => filterOutSteps.includes(stage.id)) || []
    }

    return []
  }, [currentStep?.id, getChoicePath, currentValues])

  React.useEffect(() => {
    allStepCompleted()
  }, [specHasError])

  React.useEffect(() => {
    isStepCompleted();
  }, [currentValues, files.length, specHasError]);

  return {
    currentStepListInformation, currentStep, activeStep, currentValues, setCurrentValues, handleStep,
    referenceNumber: reference_number, getFilteredList, approve_date
  }
}
