import * as React from 'react';
import { useSelector } from 'react-redux';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { makeStyles } from '@material-ui/styles';
import { DialogActions, Theme } from '@material-ui/core';

import { STAGE } from '../../shared/WizardStep/WizardEnums';
import { WizardStepStageText } from './WizardStepStageText';
import { WizardStepHeaderTitle } from './WizardStepHeaderTitle';
import { WizardStepStageBool } from './WizardStepStageBool';
import { WizardStepStageFile } from './WizardStepStageFile';
import { WizardStepConfirmButton } from './WizardStepConfirmButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getValueKey } from "../../shared/WizardStep/wizardHelpers";
import classNames from "classnames";
import { StageType, TextValue } from "../../shared/WizardStep/WizardTypes";
import { WizardAffectPrice } from "./WizardAffectPrice";
import { WizardAffectPriceOption } from "./WizardAffectPriceOption";

type StagesType = {
  orderId: number,
  orderType: string,
  referenceNumber: string,
  user: number,
  stepId: string,
  stage: StageType,
  logStageHistory: Function
  currentValue?: boolean | Array<TextValue>;
}

const useStyles = makeStyles((theme: Theme) => ({
  row: { display: 'flex', alignItems: 'center' },
  root: {
    boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.4)',
  },
  rowSub: {
    paddingLeft: `${theme.spacing(4)}px !important`,
    marginTop: `0 !important`,
    marginBottom: theme.spacing(2),
  },
  rootSub: {
    '&:before': {
      opacity: `0 !important`
    },
  },
  details: { flexDirection: 'column', gap: theme.spacing(2) },
  inline: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: theme.spacing(2) },
  center: {
    justifyContent: 'center',
    textAlign: 'center'
  },
  header: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: 1 },
  expanded: { minHeight: 'unset !important' },
  content: { margin: `0 !important` },
  hasError: {
    width: 15, height: 15, borderRadius: 4, marginRight: 15,
    background: 'linear-gradient(to top right, rgba(255, 0, 0, 0.15), rgba(255, 0, 0, 1) 50%, rgba(255, 0, 0, 0.15))'
  }
}));


export const StageGenerator = ({
                                 orderId,
                                 orderType,
                                 referenceNumber,
                                 user,
                                 stepId,
                                 stage,
                                 logStageHistory,
                                 currentValue,
                               }: StagesType) => {
  const classes = useStyles();
  const showIcon = React.useMemo(() => {
    if (stage.type === STAGE.WARN) {
      return false;
    }

    if (stage.type === STAGE.TEXT || stage.type === STAGE.BOOL) {
      return !!(stage.uploadFiles || stage.multiple || stage.multiline);
    }

    return true;
  }, [stage.type, stage.uploadFiles, stage.multiple, stage.multiline, currentValue])

  const files = useSelector((state: GlobalStoreType) => state.orders.entities[orderId as any].files) || [];
  const [listValue, setListValue] = React.useState<Array<{
    value: string;
    affectPrice?: boolean
  }> | boolean>(currentValue || [{ value: '' }]);

  const onPress = () => {
    setExpanded(val => !val);
  }

  const getError = React.useCallback((textValue = currentValue) => {
    const key = getValueKey(stepId, stage.id);
    const isOptionalFile = stage.uploadFilesOptional ? stage.uploadFilesOptional(key, textValue, files) : false;

    if (stage.type === STAGE.FILE) {
      const filesNoRequired = (currentValue === false); 
      return !isOptionalFile && !filesNoRequired && !files.some(e => e.file_type === key)
    }

    if (stage.type === STAGE.BOOL) {
      return currentValue === undefined;
    }

    if (stage.type === STAGE.TEXT) {
      if (!textValue) {
        return true;
      }

      if (Array.isArray(textValue) && textValue.length > 0) {
        const noTextValue = textValue.some(e => e.value?.length === 0);

        if (noTextValue) {
          return true;
        }

        if (stage.affectPrice) {
          const noChooseOptions = textValue.some((item: any) => !item.hasOwnProperty('affectPrice') || (item.affectPrice && !item.affectPriceOption));

          if (noChooseOptions) {
            return true;
          }
        }
      }

      if (stage.uploadFiles) {
        if (isOptionalFile) {
          return false;
        }

        if (stage.multiple) {
          return textValue.some((_item: any, index: number) => {
            return !files.some((file: any) => file.file_type === key + index);
          });
        } else {
          return !files.some(e => e.file_type?.includes(key));
        }
      }
    }

    return false;
  }, [stage.type, currentValue, listValue, files])

  const showError = React.useMemo(() => getError(), [stage.type, currentValue, listValue, files])

  const [expanded, setExpanded] = React.useState<boolean>(showIcon && !stage.alwaysShow && showError);


  const processNextStage = (value?: boolean) => {
    logStageHistory(stepId, stage.id, stage.type === STAGE.TEXT ? listValue : value);

    const noError = !getError(listValue);
    const noStageFile = stage.type !== STAGE.FILE;
    if (noError && noStageFile) {
      setExpanded(false);
    }
  }

  const processNextStageAffectPrice = (index: number, property: 'affectPrice' | 'affectPriceOption', value: boolean | string) => {
    const newValue = (listValue as Array<TextValue>).map((e, i) => {
      if (i === index) {
        return { ...e, [property]: value }
      }

      return e;

    })

    setListValue(newValue);
    logStageHistory(stepId, stage.id, newValue);
  }

  const renderFiles = (element: number | string = '') => (
    <WizardStepStageFile
      orderType={orderType}
      label={stage.uploadFilesLabel}
      files={files} stepId={stepId} fileType={getValueKey(stepId, stage.id) + element}
      orderId={orderId} userId={user} referenceNumber={referenceNumber}
      currentValue={currentValue} processNextStage={processNextStage}
      noRequiredLabel={stage.noRequiredLabel} 
    />
  )

  const renderBoolean = () => (
    <DialogActions className={classes.center}>
      <WizardStepStageBool isSelected={currentValue === true} onClick={() => processNextStage(true)}>
        Yes
      </WizardStepStageBool>
      <WizardStepStageBool isSelected={currentValue === false} onClick={() => processNextStage(false)}>
        No
      </WizardStepStageBool>
    </DialogActions>
  );

  const renderAdditionalLineComponent = (index?: number) => {
    if (stage.uploadFiles && stage.multiple && index !== undefined) {
      const item = (listValue as Array<TextValue>)[index];

      if (!item.affectPrice) {
        item.affectPriceOption = undefined;
      }

      return (
        <>
          {stage.affectPrice && (
            <>
              <WizardAffectPrice
                index={index}
                onClick={processNextStageAffectPrice}
                currentValue={item.affectPrice}
              />
              {item.affectPrice && (
                <WizardAffectPriceOption
                  index={index}
                  onClick={processNextStageAffectPrice}
                  currentValue={item.affectPriceOption}
                />
              )}
            </>
          )}
          {renderFiles(index)}
        </>
      );
    }

    return null;
  }

  const renderText = () => (
    <WizardStepStageText
      listValue={listValue as Array<TextValue>}
      setListValue={setListValue}
      isMultiple={stage.multiple}
      isMultiline={stage.multiline}
      additionalLineComponent={renderAdditionalLineComponent}
    >
      <WizardStepConfirmButton processNextStage={processNextStage} />
    </WizardStepStageText>
  );

  const showRenderInline = React.useMemo(() => {
    if (stage.type === STAGE.BOOL) {
      return renderBoolean();
    }

    if (stage.type === STAGE.TEXT && !stage.multiple && !stage.multiline) {
      return renderText();
    }

    return null;
  }, [stepId, stage.type, stage.uploadFiles, stage.multiple, stage.multiline, currentValue, listValue, setListValue])

  const renderContent = () => {
    switch (stage.type) {
      case STAGE.BOOL:
        return null;

      case STAGE.TEXT:
        if (stage.multiple || stage.multiline) {
          return renderText();
        }

        return null;

      case STAGE.FILE:
        return renderFiles();

      case STAGE.SPEC:
        return stage.specialComponent;

      default:
        return null;
    }
  }

  const isWarning = (stage.type === STAGE.WARN);
  const getIcon = React.useMemo(() => {
    if (!showIcon || isWarning) {
      return null;
    }

    return <ExpandMoreIcon />;
  }, [showIcon, isWarning])

  if (stage.type === STAGE.NONE) {
    return null;
  }

  return (
    <Accordion
      expanded={expanded}
      className={classNames({ [classes.rowSub]: stage.isSubQuestion, [classes.rootSub]: stage.isSubQuestion })}
      classes={{
        root: classes.root,
        expanded: classNames({
          [classes.rowSub]: stage.isSubQuestion,
        }),
      }}>
      <AccordionSummary
        onClick={showIcon ? onPress : () => null}
        classes={{ content: classes.content, expanded: classes.expanded }}
        expandIcon={getIcon}
      >
        <div className={classes.header}>
          <div className={classes.row}>
            {showError && <div className={classes.hasError} />}
            <WizardStepHeaderTitle isWarning={isWarning}>{stage.label}</WizardStepHeaderTitle>
          </div>
          <div style={{ display: 'flex' }}>
            {showRenderInline}
            {showIcon ? null : <div style={{ width: 36 }} />}
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.details }}>
        {renderContent()}
        {(stage.uploadFiles && !stage.multiple) && renderFiles()}
      </AccordionDetails>
    </Accordion>
  );
}
