import * as React from 'react';
import { useSelector } from 'react-redux';
import { sortFreightRows } from '../MetaDataTable/MetaDataSortingFunctions';
import { selectPlateEntities } from '../../selectors/PlateSelectors';

import { MetaDataTable } from '../MetaDataTable/MetaDataTable';
import { selectDisplayNewOrderRow } from '../../redux/selectors';

export function FreightOrdersTable() {
  const orders: Order[] = useSelector(selectDisplayNewOrderRow(order => order.is_freight));
  const plates: Plate[] = useSelector(selectPlateEntities);

  const ordersWithFreight = orders
    .map(order => ({
      ...order,
      plates: plates.filter(plate => plate.order_id === order.id)
    }));

  return (
    <MetaDataTable
      title="Freight"
      actionType="freight"
      rows={sortFreightRows(ordersWithFreight)}
      noMoveBackward
      disableSorting
      addExtraInfo
    />
  );
}
