import * as React from 'react';
import { Checkbox, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import { Button } from '../Button';
import { DataTypeProvider } from '@devexpress/dx-react-grid';

import { ORDER_TYPE } from "../../shared/constants";
import { WizardStep } from '../WizardStep/WizardStep';
import { MeasurementWizardQuestions } from '../../shared/WizardStep/MeasurementWizardQuestions';

import { PreventPropagation } from '../PreventPropagation';

export const HistoryTableCheckboxFormatter = (props: any) => {
  return (
    <DataTypeProvider
      formatterComponent={CheckboxFormatter}
      {...props}
    />
  );
};

export const CheckboxFormatter = (test: any) => {
  const row = test.row;
  const [checked, setChecked] = React.useState(row.value || false);

  React.useEffect(() => {
    setChecked(row.value);
  }, [row.value]);

  const [isOpen, setOpen] = React.useState(false);
  const isEditable = row.step === ORDER_TYPE.MEASUREMENT;

  const onChange = () => {
    setOpen(isEditable);
  };

  return (
    <React.Fragment>
      <Checkbox
        checked={checked}
        onChange={onChange}
        disabled={!isEditable}
      />
      <PreventPropagation>
        <Dialog
          maxWidth="lg"
          open={isOpen}
          disableBackdropClick
          disableEscapeKeyDown
          fullWidth
        >
          <DialogTitle>{row.step} - edit options</DialogTitle>
          <WizardStep
            orderId={row.order_id}
            orderType={ORDER_TYPE.MEASUREMENT}
            stepList={MeasurementWizardQuestions}
          />
          <DialogActions>
            <Button variant="contained" color="primary" onClick={() => setOpen(false)}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </PreventPropagation>
    </React.Fragment>
  );
};
