import * as React from 'react';
import { DataTableInformationModal } from '../../components/DataTableInformation/DataTableInformationModal';
import { MetaDataTableRowFault } from './MetaDataTableRowFault';

type MetaDataTableRowInformationElementType = {
  isOpen: boolean,
  handleClose: () => void,
  row: any
};

export function MetaDataTableRowInformationElement({ isOpen, row, handleClose }: MetaDataTableRowInformationElementType) {
  if (!isOpen) {
    return null;
  }

  switch (row.actionType) {
    case 'deviations':
    case 'users':
      return null;

    case 'faults':
      return (
        <MetaDataTableRowFault
          faultId={row.fault_id}
          reason={row.reason}
          commentId={row.comment_id}
          commentText={row.comment_text}
          onCancel={handleClose}
        />
      );

    default:
      return (
        <DataTableInformationModal
          information={row}
          noMoveBackward={row.noMoveBackward}
          allowDeleteOrder={row.allowDeleteOrder}
          onCancel={handleClose}
        />
      );
  }
}
