import * as React from "react";

import { CompletedOrdersTableRowMobile } from "../CompletedOrders/CompletedOrdersTableRowMobile";
import { MetaDataTableRowPlate } from "./MetaDataTableRowPlate";

export const getContentComponent = (params: any): any => {
  const { row: { actionType } } = params;
  switch (actionType) {
    case "prepare":
      return <MetaDataTableRowPlate {...params}/>;

    case "measurement":
    case "freight":
      return <CompletedOrdersTableRowMobile {...params} />;

    case "approve":
    case "storage":
    case "installation":
    case "completed":
      return <CompletedOrdersTableRowMobile {...params}/>;

    case "plate":
      return <MetaDataTableRowPlate {...params}/>;

    default:
      return null;
  }
};

