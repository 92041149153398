import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MeasurementsEstimateDialog } from './MeasurementsEstimateDialog';
import { MeasurementsConfirmDialog } from './MeasurementsConfirmDialog';
import { MyDialog } from '../../components/Dialog/MyDialog';

import { fetchFilesForRecord } from '../../redux/files/FileActions';

import { measureOrder, getCutoutsDetails } from '../../redux/actions/ordersActions';
import { ORDER_TYPE, COMMENT_TYPE } from '../../shared/constants';
import { getMeasurementWizardQuestions } from "../../shared/WizardStep/MeasurementWizardQuestions";
import { WizardStateEnum } from "../../shared/WizardStep/WizardEnums";
import { WizardStep } from '../../components/WizardStep/WizardStep';
import { useErrorHandler } from "../../hooks/useErrorHandler";
import { SPECIAL_COMPONENTS } from "../../shared/WizardStep/WizardEnums";
import { formatToDate, formatToTime } from '../../services/DateFormatter';

type MeasurementsDialogType = {
  data: Order
}

export function MeasurementsDialog({ data }: MeasurementsDialogType) {
  const dispatch = useDispatch();
  const { handleError } = useErrorHandler();

  React.useEffect(() => {
    dispatch(fetchFilesForRecord(data.id));
  }, []);

  const [userId, setUserId] = React.useState<number>();

  async function submitMeasureOrder() {
    try {
      await measureOrder(data.id, { user_id: userId });
    } catch (err) {
      handleError(err);
      throw err;
    }
  }

  const isInfo = data.comments.find(element => element.comment_status && element.comment_type === COMMENT_TYPE.INFO);
  const isUncontrolled = !data.control_measures;

  const [allStepsCompleted, setAllStepsCompleted] = React.useState(false);
  
  const { list: cutoutList, hasLoaded } = useSelector((state: GlobalStoreType) => state.cutoutsDetails);
  
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const openDialog = () => setIsDialogOpen(true);
  
  React.useEffect(() => {
    if (isDialogOpen) {
      dispatch(getCutoutsDetails(data.reference_number));
      setIsDialogOpen(false);
      setIsDataLoaded(true)
    }
  }, [isDialogOpen]);
  
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);
  const [cutouts, setCutouts] = React.useState<any[]>([]);

  React.useEffect(() => {
    if (isDataLoaded && hasLoaded) {
      setCutouts(cutoutList);
      setStateSpecHasError(!cutoutList.every(cutout => cutout.status));
      setIsDataLoaded(false);
    }
  }, [hasLoaded]);
  
  const [stateSpecHasError, setStateSpecHasError] = React.useState(!cutouts.every(cutout => cutout.status));

  const specHasError = React.useMemo(() => ({
    [SPECIAL_COMPONENTS.CutoutsEdit]: stateSpecHasError
  }), [stateSpecHasError]);
  

  const onStepsCompleted = (value: boolean) => {
    setAllStepsCompleted(value);
  }

  const onSubmit = () => {
    if (allStepsCompleted) {
      submitMeasureOrder();
    }
  }

  const hasAllTabsAvailable = !!data.confirm_date;
  const stepList = React.useMemo(() => getMeasurementWizardQuestions(data), [data]);

  const setStepList = React.useMemo(() => hasAllTabsAvailable ? stepList : stepList.map(step => ({
    ...step,
    state: step.required 
      ? step.state 
      : WizardStateEnum.DISABLED
  })), [hasAllTabsAvailable, stepList]);


  const getExtraData = (spec: SPECIAL_COMPONENTS) => {
    if (spec === SPECIAL_COMPONENTS.CutoutsEdit) {      
      return { 
        orderNumber: data.reference_number,
        cutouts,
        setCutouts,
        setStateSpecHasError
      }
    }

    return {};
  }

  if (!isUncontrolled && !data.is_estimated) {
    return (
      <MeasurementsEstimateDialog
        orderId={data.id} buttonLabel="Estimate"
        date={data.estimate_date} isEstimated={data.is_estimated}
      />
    );
  }

  if (!isUncontrolled && !data.confirm_date) {
    return (
      <MeasurementsConfirmDialog
        orderId={data.id} referenceNumber={data.reference_number}
        date={data.confirm_date} estimateDate={data.estimate_date}
      />
    );
  }

  const buttonLabel = isUncontrolled
    ? <div>{formatToDate(data.estimate_date)}</div>
    : <div>{formatToDate(data.confirm_date)}<br />{formatToTime(data.confirm_date)}</div>

  return (
    <MyDialog
      buttonLabel={buttonLabel}
      color={isUncontrolled ? "secondary" : "primary"}
      customColor={isUncontrolled ? "greenBorder" : ""}
      variant={isInfo || !isUncontrolled ? "contained" : "outlined"}
      dialogTitle={data.reference_number}
      onSubmit={onSubmit}
      onSubmitClose
      onSubmitDisabled={!allStepsCompleted}
      cancelButtonText="Close"
      modalSize="xl"
      minHeight={650}
      isStepper={true}
      onOpen={openDialog}
    >
      {({ onClose }: any) => (
        <WizardStep
          orderId={data.id}
          orderType={ORDER_TYPE.MEASUREMENT}
          stepList={setStepList}
          onStepsCompleted={onStepsCompleted}
          onUserIdChange={setUserId}
          onClose={onClose}
          getExtraData={getExtraData}
          specHasError={specHasError}
        />
      )}
    </MyDialog>
  );
}
