import * as orderConstants from "../constants/ordersConstants";

const INITIAL_STATE = {
  list: [],
  hasLoaded: false,
  error: null
};

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case orderConstants.GET_CUTOUTS_DETAILS_REQUEST:
      return {
        ...state,
        hasLoaded: false
      };
    case orderConstants.GET_CUTOUTS_DETAILS_SUCCESS:
      return {
        ...state,
        list: action.payload || [],
        hasLoaded: true
      };
    case orderConstants.GET_CUTOUTS_DETAILS_FAILURE:
      return {
        ...state,
        hasLoaded: false,
        error: action.error,
      };
    default:
      return state;
  }
};
