import * as React from 'react';
import { makeStyles } from '@material-ui/styles';

import { Step, StepButton, Stepper, Theme } from '@material-ui/core';

import { useErrorHandler } from '../../hooks/useErrorHandler';
import { useUserInput } from '../../hooks/useUserInput';

import { logWizardHistory, logHistoryUpdateAction } from '../../redux/actions/ordersActions';

import { StageGenerator } from './StageGenerator';
import { WizardStepUserDialog } from "./WizardStepUserDialog";
import { useWizardStepHandler } from "./useWizardStepHandler";
import { getValueKey, prepareWizardObject } from "../../shared/WizardStep/wizardHelpers";
import { useDispatch } from "react-redux";
import { WizardSpecResolver } from "./WizardSpecResolver";
import { SPECIAL_COMPONENTS } from "../../shared/WizardStep/WizardEnums";
import { StageType, TextValue } from "../../shared/WizardStep/WizardTypes";
import { WizardStateEnum } from "../../shared/WizardStep/WizardEnums";

export type StepType = {
  id: string,
  label: string,
  stages: StageType[],
  state?: WizardStateEnum
  specialComponent?: SPECIAL_COMPONENTS,
}

type WizardStepType = {
  orderId: number,
  orderType: string,
  stepList: StepType[],
  onStepsCompleted?: (value: boolean) => void,
  onUserIdChange?: (userId: number) => void;
  onClose?: () => void;
  getExtraData?: (spec: SPECIAL_COMPONENTS) => any;
  specHasError?: { [key: string]: boolean };
}

type LogHistoryType = {
  key: string,
  value: boolean | string;
  extraValue?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  stages: {
    height: '95%',
    overflowY: "auto",
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  unsetIcon: {
    "& svg": {
      color: "yellow !important",
      "& text": {
        fill: "black !important",
        fontWeight: "bold"
      }
    },
  },
  disabledIcon: {
    "& svg": {
      color: "grey !important"
    },
  },
  undoneIcon: {
    "& svg": {
      color: "red !important"
    },
  }
}));


const stateColorClass = (state: number | undefined, classes: any) => {
  switch (state) {
    case WizardStateEnum.UNSET:
      return classes.unsetIcon;
    case WizardStateEnum.DISABLED:
      return classes.disabledIcon;
    case WizardStateEnum.NOT_DONE:
      return classes.undoneIcon;
    case WizardStateEnum.COMPLETED:
      return null;
    default:
      return;
  }
}

export const WizardStep = ({
                             orderId,
                             orderType,
                             stepList,
                             onStepsCompleted,
                             onUserIdChange,
                             onClose,
                             getExtraData,
                             specHasError
                           }: WizardStepType) => {
  const classes = useStyles();
  const {
    approve_date,
    referenceNumber,
    currentStepListInformation,
    currentStep,
    activeStep,
    currentValues,
    handleStep,
    getFilteredList,
    setCurrentValues,
  } = useWizardStepHandler({
    stepList,
    orderId,
    specHasError
  });

  const [userDialogOpen, setUserDialogOpen] = React.useState(true);
  const { onUserChange, user } = useUserInput();
  const { error, isUserError, resetError } = useErrorHandler();
  const dispatch = useDispatch();

  const logStageHistory = async (stepId: string, stageId: string, value: boolean | Array<TextValue>) => {
    const key = getValueKey(stepId, stageId);

    dispatch(logHistoryUpdateAction({
      id: approve_date ? orderId : referenceNumber,
      key,
      value,
      isOrderInfoEntity: !!approve_date
    }));

    const oldValue = currentValues[key];
    const newCurrentValue = { ...currentValues, [key]: value };
    const preparedValue = prepareWizardObject({
      stages: currentStep?.stages || [],
      currentStepId: stepId,
      currentStageId: stageId,
      currentValues: newCurrentValue,
      oldValue: oldValue,
      newValue: value
    });

    setCurrentValues(preparedValue);

    const dataToSend = Object.keys(preparedValue).map(e => ({
      key: e,
      value: typeof preparedValue[e] === "boolean" ? preparedValue[e] : true,
      extra_value: typeof preparedValue[e] === "boolean" ? '' : JSON.stringify(preparedValue[e])
    }))
    await logWizardHistory(orderId, { entries: dataToSend, user_id: user })
  }

  React.useEffect(() => {
    resetError();
    if (onUserIdChange) {
      onUserIdChange(user);
    }
  }, [user]);

  React.useEffect(() => {
    if (!onStepsCompleted) {
      return;
    }

    const allCompleted = currentStepListInformation.every(step => step.state !== WizardStateEnum.NOT_DONE);
    onStepsCompleted(allCompleted);
  }, [currentStepListInformation]);

  const activeStepIndex = currentStepListInformation.findIndex(e => e.id === activeStep);
  return (
    <React.Fragment>
      <Stepper activeStep={activeStepIndex}>
        {currentStepListInformation.map((step) => {
          const colorClass = stateColorClass(step.state, classes);
          return (
            <Step
              key={step.label}
              completed={step.state === WizardStateEnum.COMPLETED}
              className={colorClass}
            >
              <StepButton onClick={handleStep(step.id)} disabled={step.state === WizardStateEnum.DISABLED}>
                {step.label}
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
      <React.Fragment>
        <WizardStepUserDialog
          isUserDialogOpen={userDialogOpen} setUserDialogOpen={setUserDialogOpen}
          onUserChange={onUserChange} isUserError={isUserError} userId={user} error={error}
          onClose={onClose!} />
        <div className={classes.stages}>
          {currentStep?.specialComponent ? (
            <WizardSpecResolver
              specialComponent={currentStep.specialComponent!} 
              getExtraData={getExtraData!} 
            />
          ) : getFilteredList.map(stage => (
            <StageGenerator
              orderId={orderId} referenceNumber={referenceNumber} user={user}
              orderType={orderType}
              key={stage.id}
              stepId={currentStep?.id || ''}
              stage={stage} logStageHistory={logStageHistory}
              currentValue={currentValues[getValueKey(currentStep?.id || '', stage.id)]}
            />
          ))}
        </div>
      </React.Fragment>
    </React.Fragment>
  );
};
