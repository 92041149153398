import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActions, Theme, withMobileDialog } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import { InjectedProps } from '@material-ui/core/withMobileDialog';

import { COMMENT_TYPE, ORDER_TYPE, PLATE_TYPE } from '../../shared/constants';
import { Button } from '../Button';
import { EmbeddedPage } from '../EmbeddedPage';
import { DataTableInformationCancel } from './DataTableInformationCancel';
import { DataTableInformationComments } from './DataTableInformationComments';
import { DataTableInformationDelete } from './DataTableInformationDelete';
import { DataTableInformationDrawing } from './DataTableInformationDrawing';
import { DataTableInformationFault } from './DataTableInformationFault';
import { DataTableInformationHistory } from './DataTableInformationHistory';
import { DataTableInformationHold } from './DataTableInformationHold';
import { DataTableInformationInfo } from './DataTableInformationInfo';
import { DataTableInformationRestoreDate } from './DataTableInformationRestoreDate';
import { DataTableInformationTabs, TABS } from './DataTableInformationTabs';
import { PlateDeviation } from '../../containers/PlatesTableOrders/PlateDeviation';
import { DataTableInformationBack } from './DataTableInformationBack';
import { MeasurementsEstimateDialog } from '../../containers/MeasurementsOrders/MeasurementsEstimateDialog';
import { MeasurementsConfirmDialog } from '../../containers/MeasurementsOrders/MeasurementsConfirmDialog';
import { FreightRestoreDate } from '../../containers/FreightOrders/FreightRestoreDate';
import { InstallationConfirmDateDialog } from '../../containers/InstallationOrders/InstallationConfirmDateDialog';
import { InstallationRestoreDate } from '../../containers/InstallationOrders/InstallationRestoreDate';
import { fetchCommentsForRecord } from '../../redux/comments/CommentActions';
import { fetchFilesForRecord } from '../../redux/files/FileActions';
import { fetchHistoryForRecord } from '../../redux/history/HistoryActions';
import { MyDialogTitle } from '../Dialog/MyDialogTitle';
import { MyDialogContent } from '../Dialog/MyDialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';

type DataTableInformationModalType = {
  information: Plate & Order & { actionType: string },
  onCancel: () => void,
  noMoveBackward: boolean;
  allowDeleteOrder?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    height: '100%'
  },
  content: {
    marginTop: theme.spacing(3),
    flex: '1 1 100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      overflowY: 'auto',
    },
  },
  leftButtons: {
    display: 'flex',
    flex: 1
  },
}));

const getPrependName = ({ reference_number, order_reference_number, installation_date }: any) => {
  if (order_reference_number) {
    return `MEASUREMENTS-ORDER-${order_reference_number}-PLATE-${reference_number}`;
  }
  if (installation_date) {
    return `INSTALLATION-ORDER-${reference_number}`;
  }

  return `MEASUREMENTS-ORDER-${reference_number}`;
};

function DataTableInformationModal({ onCancel, fullScreen, noMoveBackward, allowDeleteOrder, information: { actionType, id, order_id, order_reference_number, reference_number, offerVersion, offer_version, cancel_date, created_at, installation_confirmed, installation_date, estimate_date, confirm_date, measure_date, is_estimated, pickup_date, current_stage, plates } }: DataTableInformationModalType & InjectedProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme<Theme>();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const isPlate = !!order_id;

  const comments = useSelector((state: GlobalStoreType) => isPlate ? state.plates.entities[id].comments : state.orders.entities[id].comments) || [];
  const files = useSelector((state: GlobalStoreType) => isPlate ? state.orders.entities[order_id].files : state.orders.entities[id].files) || [];
  const actions = useSelector((state: GlobalStoreType) => isPlate ? state.plates.entities[id].actions : state.orders.entities[id].actions) || [];

  if (isPlate) {
    actions.forEach(x => x.plate_id = id);
  }
  const createdAt = useSelector((state: GlobalStoreType) => isPlate ? state.orders.entities[order_id].created_at : created_at);

  React.useEffect(() => {
    const callPlateId = isPlate ? id : undefined;
    const callOrderId = isPlate ? order_id : id;
    dispatch(fetchCommentsForRecord(callOrderId, callPlateId));
    dispatch(fetchFilesForRecord(callOrderId, callPlateId));
    dispatch(fetchHistoryForRecord(callOrderId, callPlateId));
  }, []);

  const [tabValue, setTabValue] = React.useState(TABS.COMMENTS);
  const regularComments = comments.filter(comment => [COMMENT_TYPE.REGULAR, COMMENT_TYPE.MEASUREMENT].includes(comment.comment_type as any));
  const onHoldComments = comments
    .filter(comment => order_id ? (comment.order_id === order_id && !comment.plate_id || comment.order_id === order_id && comment.plate_id === id) : comment.order_id === id)
    .filter(comment => [COMMENT_TYPE.ON_HOLD, COMMENT_TYPE.ON_HOLD_OFF, COMMENT_TYPE.ON_HOLD_FLOW].includes(comment.comment_type as any));

  const infoComments = comments.filter(comment => comment.comment_type === COMMENT_TYPE.INFO);

  const baseUrl = useSelector((state: GlobalStoreType) => state.configuration.orderBaseUrl);
  const orderNumber = isPlate ? order_reference_number : reference_number;
  const orderVersion = isPlate ? reference_number : offerVersion || offer_version;
  const orderSummaryExternalURL = `${baseUrl}/external-iframe/#/ordre/${orderNumber}/${orderVersion}/order-summary-external`;
  const orderReportExternalURL = `${baseUrl}/external-iframe/#/ordre/${orderNumber}/${orderVersion}/oppmaaling/ferdig`;

  function renderContent(value: TABS) {
    switch (value) {
      case TABS.COMMENTS:
        return (
          <DataTableInformationComments
            plateId={order_id ? id : undefined}
            orderId={order_id || id}
            comments={regularComments}
          />
        );
      case TABS.HOLD:
        return (
          <DataTableInformationHold
            files={files.filter(file => file.file_type === 'ON_HOLD')}
            plateId={order_id ? id : undefined}
            orderId={order_id || id}
            comments={onHoldComments}
            reference_number={reference_number}
            noHoldOptions={actionType === "installation"}
          />
        );
      case TABS.INFO:
        return (
          <DataTableInformationInfo
            plateId={order_id ? id : undefined}
            orderId={order_id || id}
            comments={infoComments}
          />
        );
      case TABS.DRAWING:
        return (
          <DataTableInformationDrawing
            files={files.filter(file => file.file_type === 'DRAWING')}
            orderId={order_id || id}
            isPlate={false}
            prependName={getPrependName({ order_reference_number, reference_number, installation_date })}
            type="DRAWING"
            label="UPLOAD DRAWING"
            allowMonter={actionType === "installation"}
          />
        );
      case TABS.PICTURES:
        return (
          <DataTableInformationDrawing
            files={files.filter(file => file.file_type === 'PICTURE')}
            orderId={order_id || id}
            isPlate={false}
            prependName={getPrependName({ order_reference_number, reference_number, installation_date })}
            type="PICTURE"
            allowMonter={actionType === "installation" || actionType === "completed"}
          />
        );
      case TABS.DEVIATION:
        return (
          <PlateDeviation
            id={order_id ? id : undefined}
            order_id={order_id || id}
            reference_number={reference_number}
            files={files}
            comments={comments}
          />
        );
      case TABS.HISTORY:
        const withPlateId = plates && plates.some(plate => plate.current_stage === PLATE_TYPE.INSTALLATION || plate.current_stage === PLATE_TYPE.COMPLETED);
        return (
          <DataTableInformationHistory
            actions={[{ key: 'Order appeared in app', created_at: createdAt, id: 0 }, ...actions]}
            withPlateId={withPlateId || current_stage === PLATE_TYPE.INSTALLATION || current_stage === PLATE_TYPE.COMPLETED}
          />
        );
      case TABS.ORDREBESKRIVELSE:
        return (
          <EmbeddedPage url={orderSummaryExternalURL} />
        );
      case TABS.REPORT:
        return (
          <EmbeddedPage url={orderReportExternalURL} />
        );
    }
  }

  const getTitle = () => {
    if (order_reference_number && reference_number) {
      return `Order: ${order_reference_number}, Plate: ${reference_number}`;
    }

    if (order_reference_number && !reference_number) {
      return `Order: ${order_reference_number}`;
    }

    return `Order: ${reference_number}`;
  };

  return (
    <Dialog
      maxWidth="xl"
      fullScreen={fullScreen}
      fullWidth
      open
      onClose={onCancel}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.dialog }}
    >
      <MyDialogTitle>{getTitle()}</MyDialogTitle>
      <MyDialogContent>
        <DataTableInformationTabs value={tabValue} setValue={setTabValue} />
        <div className={classes.content}>
          {renderContent(tabValue)}
        </div>
      </MyDialogContent>
      <DialogActions>
        <div className={classes.leftButtons}>
          {allowDeleteOrder && !order_id && (
            <DataTableInformationDelete orderId={id} referenceNumber={reference_number} />
          )}
          {!cancel_date && isDesktop && (
            <DataTableInformationCancel
              orderId={order_id || id}
              referenceNumber={order_reference_number || reference_number}
            />
          )}
          {order_id && (
            <DataTableInformationFault
              files={files}
              plate_id={id}
              order_id={order_id}
              reference_number={reference_number}
            />
          )}
          {!noMoveBackward && (
            <div style={{ marginLeft: 8 }}>
              <DataTableInformationBack plate_id={order_id ? id : undefined} order_id={order_id || id}
                                        title={getTitle()} current_stage={current_stage} actionType={actionType} />
            </div>
          )}
          {estimate_date && !measure_date && (
            <div style={{ marginLeft: 8 }}>
              <MeasurementsEstimateDialog
                orderId={id} date={estimate_date} isEstimated={is_estimated} buttonLabel="Set Date"
              />
            </div>
          )}
          {(!estimate_date || is_estimated) && !measure_date && !order_id && (
            <DataTableInformationRestoreDate orderId={id} referenceNumber={reference_number} callback={onCancel} />
          )}
          {confirm_date && !measure_date && (
            <div style={{ marginLeft: 8 }}>
              <MeasurementsConfirmDialog
                orderId={id} referenceNumber={reference_number} date={confirm_date} buttonLabel="Confirm"
              />
            </div>
          )}
          {actionType === 'freight' && pickup_date && (
            <div style={{ marginLeft: 8 }}>
              <FreightRestoreDate orderId={id} referenceNumber={reference_number} disabled={installation_confirmed} callback={onCancel} />
            </div>
          )}
          {actionType === 'installation' && (
            <>
              {installation_confirmed && (
                <div style={{ marginLeft: 8 }}>
                    <InstallationConfirmDateDialog
                      orderId={id}
                      plates={plates!}
                      referenceNumber={reference_number}
                      pickupDate={pickup_date}
                      estimatedDate={installation_date}
                    />
                </div>
              )}
              {installation_date && (
                <div style={{ marginLeft: 8 }}>
                  <InstallationRestoreDate
                    orderId={id}
                    referenceNumber={reference_number}
                    callback={onCancel}
                  />
                </div>
              )}
            </>
          )}
        </div>
        <Button variant="text" color="default" onClick={onCancel}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const connected = withMobileDialog<DataTableInformationModalType>()(DataTableInformationModal);
export { connected as DataTableInformationModal };
