import * as React from "react";
import { Avatar, Chip, TextField, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import { PlateTypeMultiSelect } from "./PlateTypeMultiSelect";
import { SetPlateTypesCount } from "./SetPlateTypesCount";

type SetPlateHolesType = {
  dictionary: HoleDictionary[],
  onPlatesChange: (value: number) => void,
  onMultiSelectChange: (value: any, values: any) => void,
  onNoEdgePolish: (value: any, values: any) => void,
  onNoManualFinish: (value: any, values: any) => void,
  onEdgePolishOnCnc: (value: any, values: any) => void,
  onPlateNumberChange: (value: any, values: any) => void,
  onTypeCountChange: (value: any) => void,
  platesNumber: number,
  platesArray: SetPlate[],
  platesArrayGroup: Array<{ type: string; count: number; }>
};

const useStyles = makeStyles((theme: Theme) => ({
  root: { display: 'flex' },
  left: { flex: 3 },
  right: { flex: 2, marginLeft: theme.spacing(3) },
  cutout: { marginTop: theme.spacing(1) }
}));


export function SetPlateHoles({
                                dictionary,
                                onPlatesChange,
                                onMultiSelectChange,
                                onNoEdgePolish,
                                onNoManualFinish,
                                onEdgePolishOnCnc,
                                onPlateNumberChange,
                                onTypeCountChange,
                                platesNumber,
                                platesArray,
                                platesArrayGroup
                              }: SetPlateHolesType) {
  const classes = useStyles();
  const [isCountModalOpen, setCountModal] = React.useState(false);
  const [currentType, setCurrentType] = React.useState(null);
  const [currentPlate, setCurrentPlate] = React.useState(null);

  const onTextChange = (evt: React.ChangeEvent<HTMLInputElement>) => onPlatesChange(parseInt(evt.target.value));

  const handleOpen = (plate: any, type: any) => () => {
    setCurrentType(type);
    setCurrentPlate(plate);
    setCountModal(true);
  };
  const handleClose = () => setCountModal(false);

  const handleTypeCountChange = (value: any) => {
    onTypeCountChange(value);
    handleClose();
  };

  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <TextField
          onChange={onTextChange}
          fullWidth
          required
          label="Plates"
          type="number"
          error={platesNumber < 1}
          value={platesNumber || ''}
          autoComplete="off"
        />

        {platesArray.map(element => {
            return (
              <React.Fragment key={element.id}>
                <PlateTypeMultiSelect
                  plate={element}
                  dictionary={dictionary}
                  onMultiSelectChange={onMultiSelectChange}
                  onNoEdgePolish={onNoEdgePolish}
                  onNoManualFinish={onNoManualFinish}
                  onEdgePolishOnCnc={onEdgePolishOnCnc}
                  onPlateNumberChange={onPlateNumberChange}
                  handleOpen={handleOpen}
                />
              </React.Fragment>
            )
          }
        )}
      </div>
      <div className={classes.right}>
        {dictionary.map((cutout: any, index: number) => {
          const element = platesArrayGroup.find(element => element.type === cutout.key);
          const isCompleted = element && element.count === cutout.count;

          return (
            <div key={index} className={classes.cutout}>
              <Chip
                label={cutout.label}
                variant="outlined"
                color={isCompleted ? "primary" : "secondary"}
                avatar={<Avatar>{cutout.count}</Avatar>}
              />
            </div>
          );
        })}
      </div>

      {isCountModalOpen && (
        <SetPlateTypesCount
          handleClose={handleClose}
          handleSubmit={handleTypeCountChange}
          type={currentType!}
          plate={currentPlate!}
        />
      )}
    </div>
  );
}
