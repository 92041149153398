import * as React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { DatePicker } from 'material-ui-pickers';
import { Theme, Typography } from '@material-ui/core';
import { ErrorMessage } from '../../components/Common/ErrorMessage';

import { MyDialog } from '../../components/Dialog/MyDialog';

import { MyDialogUser } from '../../components/Dialog/MyDialogUser';
import { useErrorHandler } from '../../hooks/useErrorHandler';
import { useUserInput } from '../../hooks/useUserInput';
import { installationDate } from '../../redux/actions/ordersActions';
import { useIsAdmin, useIsMonter } from '../withIsAdmin';
import * as dateFns from 'date-fns';
import { getFutureDateOrToday, addBusinessDays } from '../../services/DateFormatter';

type InstallationEstimateDateDialogType = {
  orderId: number,
  deliveryDate: string,
  pickupDate: Date
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 100%'
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    "& > div": {
      margin: theme.spacing(1)
    }
  },
  datePicker: {
    padding: '0 25%'
  }
}));

export function InstallationEstimateDateDialog({ orderId, deliveryDate, pickupDate }: InstallationEstimateDateDialogType) {
  const isAdmin = useIsAdmin();
  const isMonter = useIsMonter();
  const classes = useStyles();

  const { onUserChange, user, resetUser } = useUserInput();
  const { error, handleError, resetError, isUserError } = useErrorHandler();

  const installationAddDays = useSelector((state: GlobalStoreType) => {
    const sellerTeams = state.orders.entities[orderId].sellerTeams;
    const installationAddDays = sellerTeams?.installationAddDays ?? 2;

    return installationAddDays;
  });

  const conditionalDate = getFutureDateOrToday(pickupDate || deliveryDate);
  const minDate = addBusinessDays(conditionalDate, installationAddDays);

  const [_date, changeDate] = React.useState(minDate);

  React.useEffect(() => {
    changeDate(minDate);
  }, [pickupDate, deliveryDate]);

  const onChangeDate = async (newTime: Date) => changeDate(newTime);

  function resetLocalState() {
    resetUser();
  }

  async function submitInstallationDate() {
    try {
      const date = dateFns.format(_date, "yyyy-MM-dd");
      await installationDate(orderId, { date, user_id: user });
      resetLocalState();
    } catch (err) {
      handleError(err);
      throw err;
    }
  }

  React.useEffect(() => {
    resetError();
  }, [user]);

  if (!(isAdmin || isMonter)) {
    return null;
  }

  return (
    <MyDialog
      buttonLabel={dateFns.format(minDate, 'dd-MM-yyyy')}
      dialogTitle="Estimate"
      color={'secondary'}
      variant={'outlined'}
      onSubmit={submitInstallationDate}
      modalSize="xs"
    >
      <div className={classes.root}>
        <div className={classes.row}>
          <Typography variant="subtitle2">Estimate date of installation</Typography>
        </div>
        <div className={`${classes.row} ${classes.datePicker}`}>
          <DatePicker
            fullWidth
            variant="outlined"
            label="Date"
            value={_date}
            minDate={minDate}
            onChange={onChangeDate}
            format="dd-MM-yyyy"
          />
        </div>
        <div className={classes.row}>
          <MyDialogUser onChange={onUserChange} userError={isUserError} user={user} />
        </div>
        <ErrorMessage message={error} isVisible={!!error} />
      </div>
    </MyDialog>
  );
}
