import * as React from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogTitle,
  TextField, Theme, withMobileDialog, Checkbox,
  FormControlLabel, FormControl,
  InputLabel, Select, MenuItem
} from '@material-ui/core';
import { InjectedProps } from '@material-ui/core/withMobileDialog';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { useErrorHandler } from '../../hooks/useErrorHandler';

import { insertOnHoldComment } from '../../redux/actions/commentActions';
import { useUserInput } from '../../hooks/useUserInput';
import { Row } from '../Common/Row';
import { Button } from '../Button';
import { ErrorMessage } from '../Common/ErrorMessage';
import { MyDialogUser } from '../Dialog/MyDialogUser';
import { PreventPropagation } from '../PreventPropagation';

import { COMMENT_TYPE } from '../../shared/constants';
import { FileUploader } from '../FileUploader';
import { FileUploadTable } from '../FileUploadTable/FileUploadTable';
import { FileDropzone } from '../Common/FileDropzone';
import { useFileUploader } from '../../hooks/useFileUploader';

type DataTableInformationCommentAddType = {
  commentId?: number,
  plateId?: number,
  orderId: number,
  commentText?: string,
  filter?: string[],
  isDisabled?: boolean,
  canAdd?: boolean,
  withIsSeparately?: boolean,
  withHoldOptions?: boolean,
  insertOnHoldComment: (params: CommentAction) => void,
  reference_number: string,
  files: FileType[],
  multiple: boolean,
  label: string
};

type HoldOption = {
  [key: string]: {
    extraInfo: boolean,
    uploadFiles: boolean,
    sendToCustomer: boolean
  }
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(3),
  },
  comment: {
    flex: 1,
    width: '100%'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
  },
  button: {
    width: 'auto',
    display: 'flex',
    flex: 1
  },
  leftButtons: {
    display: 'flex',
    flex: 1
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  select: {
    width: '300px',
    padding: theme.spacing(1)
  },
  table: {
    flex: 1,
    overflowY: 'auto',
    marginBottom: theme.spacing(2)
  },
  middle: {
    marginTop: theme.spacing(1)
  }
}));

function DataTableInformationCommentHold({
                                           orderId, plateId, isDisabled, canAdd, commentText = '', withHoldOptions, reference_number, files, insertOnHoldComment
                                         }: DataTableInformationCommentAddType & InjectedProps) {
  const classes = useStyles();
  const [comment, setComment] = React.useState(commentText);
  const [isOpen, setOpen] = React.useState(false);
  const [isSeparately, setIsSeparately] = React.useState(false);

  const holdOptionsSettings: HoldOption = {
    "Approve new drawing": { extraInfo: false, uploadFiles: true, sendToCustomer: true },
    "Approve measured drawing": { extraInfo: true, uploadFiles: true, sendToCustomer: true },
    "Missing information customer": { extraInfo: true, uploadFiles: true, sendToCustomer: true },
    "Missing information SG": { extraInfo: true, uploadFiles: true, sendToCustomer: false },
    "Materials not recieved": { extraInfo: false, uploadFiles: false, sendToCustomer: false },
    "Need to change drawing": { extraInfo: false, uploadFiles: false, sendToCustomer: false },
    "Details need to be clarified": { extraInfo: false, uploadFiles: false, sendToCustomer: false },
    "Installer waiting for info": { extraInfo: true, uploadFiles: true, sendToCustomer: false },
  }

  const holdOptions = Object.keys(holdOptionsSettings);

  const [holdOption, setHoldOption] = React.useState("");
  const [isExtraInfo, setExtraInfo] = React.useState(false);
  const [showUploadFiles, setShowUploadFiles] = React.useState(false);

  const [showSendToCustomer, setShowSendToCustomer] = React.useState(false);
  const [isSendToCustomer, setIsSendToCustomer] = React.useState(false);

  const handleHoldOption = (event: React.ChangeEvent<any>) => {
    setHoldOption(event.target.value);
    setExtraInfo(holdOptionsSettings[event.target.value].extraInfo);
    setShowUploadFiles(holdOptionsSettings[event.target.value].uploadFiles);
    setShowSendToCustomer(holdOptionsSettings[event.target.value].sendToCustomer);
    if (!isExtraInfo) {
      setHoldItems([]);
    }
  };

  const [holdItems, setHoldItems] = React.useState(Array<string>());
  const onChangeHoldPoint = (idx: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const newItems: string[] = holdItems.map((item, i) => {
      if (i !== idx) return item;
      return e.target.value;
    });
    setHoldItems(newItems);
  }
  const onAddHoldItem = () => setHoldItems([...holdItems, ""]);
  const onDeleteHoldItem = (idx: number) => () => {
    const newItems: string[] = holdItems.filter((_item, i) => i !== idx);
    setHoldItems(newItems);
  }

  const { onUserChange, user, resetUser } = useUserInput();
  const { error, handleError, isUserError, resetError } = useErrorHandler();

  const { filesState, handleSetFilesAndSend, resetState } = useFileUploader({
    prependName: `ON_HOLD-ORDER-${reference_number}`,
    type: 'ON_HOLD',
    orderId: orderId,
    userId: user,
    isPlate: false
  });

  const [ oldFiles ] = React.useState([...files]);

  const onUpload = async (evt: HTMLInputEvent) => {
    try {
      await handleSetFilesAndSend(evt);
      resetState();
    } catch (err) {
      handleError(err);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => setComment(e.target.value);

  async function onSubmitDialog() {
    if (plateId) {
      setOpen(true);
    } else {
      await onSubmit();
    }
  }

  async function onSubmit() {
    try {
      const oldFileKeys = oldFiles.map(file => file.key);
      const filesInfo = files.filter(file => !oldFileKeys.includes(file.key));
      await insertOnHoldComment({ orderId, plateId, comment_text: comment, user_id: user, is_separately: isSeparately, hold_option: holdOption, extra_info: holdItems, is_send_to_customer: isSendToCustomer, files_info: filesInfo });
      clear();
    } catch (err) {
      handleError(err);
      setOpen(false);
      throw err;
    }
  }

  async function onSubmitAll() {
    try {
      await insertOnHoldComment({ orderId, comment_text: comment, user_id: user });
      clear();
    } catch (err) {
      handleError(err);
      setOpen(false);
      throw err;
    }
  }

  function clear() {
    setComment('');
    resetUser();
    setOpen(false);
  }

  React.useEffect(() => {
    resetError();
  }, [comment, user]);

  return (
    <React.Fragment>
      <div className={classes.root}>
        {withHoldOptions && (
          <FormControl className={classes.formControl}>
            <InputLabel id="select-label">Hold option</InputLabel>
            <Select
              labelId="select-label"
              value={holdOption}
              onChange={handleHoldOption}
              className={classes.select}
              disabled={isDisabled}
				    >
              {holdOptions.map(item => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
            {showSendToCustomer && (
              <FormControlLabel control={
                  <Checkbox
                    checked={isSendToCustomer}
                    onChange={() => setIsSendToCustomer(!isSendToCustomer)}
                    color="primary"
                  />
                }
                label="Send to Customer"
              />
            )}
          </FormControl>
        )}
        {isExtraInfo && (
          <FormControl className={classes.formControl}>
            <label>Missing items</label>
            {holdItems.map((item, idx) => (
              <Row key={idx} className={classes.row}>
                <TextField
                  fullWidth
                  label={`Item #${idx + 1}`}
                  value={item}
                  onChange={onChangeHoldPoint(idx)}
                />
                <Button variant="contained" color="secondary" onClick={onDeleteHoldItem(idx)}>Remove</Button>
              </Row>
            ))}
            <Row>
              <Button variant="contained" onClick={onAddHoldItem} className={classes.button}>Add item</Button>
            </Row>
          </FormControl>
        )}
        <TextField
          value={comment}
          onChange={onChange}
          multiline
          rows={2}
          className={classes.comment}
          label="Comment"
          disabled={isDisabled}
        />
        {plateId && (<FormControlLabel
            control={
             <Checkbox
                checked={isSeparately}
                onChange={() => setIsSeparately(!isSeparately)}
                color="primary"
             />
            }
            label="Will be mounted separately"
          />
        )}
        <div className={classes.row}>
          <MyDialogUser onChange={onUserChange} userError={isUserError} user={user} disabled={isDisabled} />
          <div className={classes.row}>
            <Button variant="contained" onClick={onSubmitDialog} disabled={canAdd || !user}>Submit</Button>
          </div>
        </div>
        <ErrorMessage message={error} isVisible={!!error} />
        <FileDropzone onUpload={onUpload}>
          {showUploadFiles && (
            <div className={classes.middle}>
              <FileUploader addFiles={onUpload} />
            </div>
          )}
          <div className={classes.table}>
            <FileUploadTable
              files={[...files, ...filesState].filter((element: any) => element.file_type === COMMENT_TYPE.ON_HOLD) as any}
            />
          </div>
        </FileDropzone>
      </div>
      <PreventPropagation>
        <Dialog
          maxWidth="sm"
          open={isOpen}
          onClose={() => setOpen(false)}
          disableBackdropClick
          disableEscapeKeyDown
          fullWidth
        >
          <DialogTitle id="form-dialog-title">On Hold All?</DialogTitle>
          <DialogContent>
            <div>
              Do you want to put On Hold all plates within that order?
            </div>
          </DialogContent>
          <DialogActions>
            <div className={classes.leftButtons}>
              <Button color="default" onClick={() => setOpen(false)}>
                Cancel
              </Button>
            </div>
            <Button variant="contained" color="secondary" onClick={onSubmitAll}>
              Hold all plates
            </Button>

            <Button variant="contained" color="primary" onClick={onSubmit}>
              Hold only this
            </Button>
          </DialogActions>
        </Dialog>
      </PreventPropagation>
    </React.Fragment>
  );
}

DataTableInformationCommentHold.defaultProps = {
  commentText: '',
  multiple: true,
  label: 'Upload Files'
};

const connected = connect(null, { insertOnHoldComment })(withMobileDialog<DataTableInformationCommentAddType>()(DataTableInformationCommentHold));
export { connected as DataTableInformationCommentHold };
