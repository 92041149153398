import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

type ContentRowType = {
  label: string
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

export const ContentRow: React.FunctionComponent<ContentRowType> = ({ children, label }) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <strong>{label}:</strong>
      <span> {children}</span>
    </div>
  );
};
