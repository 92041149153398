import * as React from "react";
import { Typography } from "@material-ui/core";
import { red, green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/styles";
import classnames from "classnames";
import { PLATE_TYPE } from "../shared/constants";

type PlateBadgeType = {
  actionType: string,
  plate: Plate
};

const useStyles = makeStyles(() => ({
  badge: {
    display: "block",
    borderRadius: 20,
    padding: 6,
    margin: 2
  },
  active: {
    backgroundColor: green[500]
  },
  done: {
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: green[500],
    color: green[500]
  },
  wrong: {
    backgroundColor: red[500]
  }
}));

export function PlateBadge({ actionType, plate: { reference_number, current_stage, has_freight }}: PlateBadgeType) {
  const classes = useStyles();

  const isPacked = (current_stage === PLATE_TYPE.PACKED); 
  //legacy: old flow before 2025
  const oldFlow = (has_freight === null);
  const isInstallation = (current_stage === PLATE_TYPE.INSTALLATION);
  //----------------------------
  const isCompleted = (current_stage === PLATE_TYPE.COMPLETED);

  const getStageConfig = (actionType: string) => {
    if (actionType === "freight") {
      return {
        active: isPacked && !has_freight,
        done: has_freight,
        wrong: !isCompleted && !isPacked,
      };
    } else if (oldFlow) {
      return {
        active: isInstallation,
        done: isCompleted,
        wrong: !isCompleted && !isInstallation,
      };
    } else {
      return {
        active: isPacked && has_freight,
        done: isCompleted,
        wrong: !isCompleted && (!isPacked || !has_freight),
      };
    }
  };
  
  const className = classnames(classes.badge, {
    [classes.active]: getStageConfig(actionType).active,
    [classes.done]: getStageConfig(actionType).done,
    [classes.wrong]: getStageConfig(actionType).wrong,
  });

  return (
    <Typography variant="button" className={className}>{reference_number}</Typography>
  );
}


