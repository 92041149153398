import { InjectedProps } from "@material-ui/core/withMobileDialog";
import React, { useCallback, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Theme, withMobileDialog } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { DatePicker } from "material-ui-pickers";
import { formatToDate } from '../../services/DateFormatter';
import classnames from "classnames";

import { Button } from "../../components/Button";
import { Tooltip } from '@material-ui/core';

import { PreventPropagation } from "../../components/PreventPropagation";
import { setDeliveryDate } from "../../redux/actions/ordersActions";
import { setPlateDeliveryDate } from "../../redux/actions/plateActions";

const useStyles = makeStyles((theme: Theme) => ({
  root: { textAlign: "right", cursor: "pointer" },
  input: { padding: theme.spacing(0.8) },
  smallInput: { padding: theme.spacing(0.5) },
  picker: { maxWidth: 200 },
  leftButtons: { display: "flex", flex: 1 },
  textInfo: { fontSize: theme.spacing(2) },
}));

type PlateDeliveryDateInlineType = {
  plateId?: number,
  orderId: number,
  date: string,
  small?: boolean,
  disabled?: boolean,
  clearable: boolean
}

const getFormattedDate = (value?: string) => {
  return value ? formatToDate(value) : null;
};

function PlateDeliveryDateInline({ plateId, orderId, date, small, disabled, clearable }: PlateDeliveryDateInlineType & InjectedProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");

  const onDateChangePlate = async () => {
    await dispatch(setPlateDeliveryDate(plateId, getFormattedDate(selectedValue)));
    setOpen(false);
  };

  const onDateChangeOrder = async () => {
    await dispatch(setDeliveryDate(orderId, getFormattedDate(selectedValue)));
    setOpen(false);
  };

  const onDateChange = useCallback(async (newDate: string) => {
    if (!!plateId) {
      setOpen(true);
      setSelectedValue(newDate);
    } else {
      await dispatch(setDeliveryDate(orderId, getFormattedDate(newDate)));
    }
  }, []);

  const inputClasses = classnames({
    [classes.root]: true,
    [classes.input]: !small,
    [classes.smallInput]: small
  });

  return (
    <React.Fragment>
      <PreventPropagation>
        <Tooltip 
          title={disabled ? (
            <span className={classes.textInfo}>
              Date for freight is confirmed.<br/>
              This need to be restored first.
            </span>
          ) : ""} 
          placement="top"
          arrow
        >
          <DatePicker
            fullWidth
            animateYearScrolling
            variant="outlined"
            value={date}
            onChange={onDateChange}
            disablePast
            format="dd-MM-yyyy"
            InputProps={{ classes: { input: inputClasses } }}
            className={classes.picker}
            minDateMessage=""
            disabled={disabled}
            clearable={clearable}
          />
        </Tooltip>
      </PreventPropagation>
      <PreventPropagation>
        <Dialog
          maxWidth="sm"
          open={open}
          onClose={() => setOpen(false)}
          disableBackdropClick
          disableEscapeKeyDown
          fullWidth
        >
          <DialogTitle id="form-dialog-title">Change Date All?</DialogTitle>
          <DialogContent>
            <div>
              Do you want to set a date for all plates within order for a
              date {formatToDate(selectedValue)}?
            </div>
          </DialogContent>
          <DialogActions>
            <div className={classes.leftButtons}>
              <Button color="default" onClick={() => setOpen(false)}>
                Cancel
              </Button>
            </div>
            <Button variant="contained" color="secondary" onClick={onDateChangeOrder}>
              Change all plate
            </Button>

            <Button variant="contained" color="primary" onClick={onDateChangePlate}>
              Change only this
            </Button>
          </DialogActions>
        </Dialog>
      </PreventPropagation>
    </React.Fragment>
  );
}

const connected = withMobileDialog<PlateDeliveryDateInlineType>()(PlateDeliveryDateInline);
export { connected as PlateDeliveryDateInline };
