import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  InputLabel,
  Input,
  MenuItem,
  FormControl,
  Select,
  Chip,
  Avatar,
  Checkbox,
  FormControlLabel,
  TextField
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0)
  },
  formControl: {
    width: '100%',
  },
  chips: {
    marginTop: theme.spacing(1)
  },
  chip: {
    margin: theme.spacing(0.5)
  },
  comment: {
    flex: 1,
    width: '100%'
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    }
  },
};

export function PlateTypeMultiSelect({ plate, dictionary, onMultiSelectChange, onNoEdgePolish, onNoManualFinish, onEdgePolishOnCnc, onPlateNumberChange, handleOpen }) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  const handleNoEdgePolish = () => onNoEdgePolish(plate.id);
  const handleNoManualFinish = () => onNoManualFinish(plate.id);
  const handleEdgePolishOnCnc = () => onEdgePolishOnCnc(plate.id);

  const onChangePlateNumber = (e) => onPlateNumberChange(plate.id, e.target.value);

  const onSelect = (evt) => {
    onMultiSelectChange(evt);
    onClose();
  };

  return (
    <div className={classes.root}>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="select-multiple">Plate #{plate.id}</InputLabel>
        <Select
          multiple
          value={plate.types.map(type => type.type)}
          input={<Input id="select-multiple" />}
          inputProps={{ name: plate.id.toString() }}
          MenuProps={MenuProps}
          open={open}
          onChange={onSelect}
          onClose={onClose}
          onOpen={onOpen}
        >
          {dictionary.map(({ key, label }) => (
            <MenuItem key={key} value={key}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className={classes.chips}>
        {plate.types.map(type => (
          <Chip
            color={'primary'}
            key={type.type}
            label={type.type}
            className={classes.chip}
            avatar={<Avatar>{type.count}</Avatar>}
            onClick={handleOpen(plate, type)}
          />
        ))}
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={plate.noEdgePolish}
              onClick={handleNoEdgePolish}
            />
          }
          label="No Edge Polish"
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={plate.noManualFinish}
              onClick={handleNoManualFinish}
            />
          }
          label="No Manual Finish"
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={plate.edgePolishOnCnc}
              onClick={handleEdgePolishOnCnc}
            />
          }
          label="Edgepolish on CNC"
        />

        <TextField
          value={plate.plateNumber}
          onChange={onChangePlateNumber}
          className={classes.comment}
          label="Platenumber"
          error={!plate.plateNumber || plate.plateNumber.length > 200}
          required
        />
      </div>
    </div>
  );
}
