import * as React from 'react';
import { Avatar, Chip, Typography } from '@material-ui/core';

import { DataTableDate } from '../../components/DataTable/DataTableDate';
import { DataTableDeliveryMethod } from '../../components/DataTable/DataTableDeliveryMethod';
import { COMMENT_TYPE, PLATE_TYPE } from '../../shared/constants';
import { PlateProceedDialog } from '../PlatesTableOrders/PlateProceedDialog';
import { dataTableClasses } from '../../components/DataTable/DataTableStyles';

interface RowDetailsPlateData extends Plate {
  checkpoints: CheckpointType[]
}

interface RowDetailsMaterialData extends Material {
  checkpoints: CheckpointType[]
}

type RowDetail = {
  row: RowDetailsPlateData & RowDetailsMaterialData
}


export const MetaDataTableRowPlate = ({ row }: RowDetail) => {
  const classes = dataTableClasses();
  const { name, thickness, type, checkpoints, ...data } = row;
  const delivery_date_disable = !!row.order_pickup_date;

  const isOnHold = !!data.comments?.find(comment => [COMMENT_TYPE.ON_HOLD, COMMENT_TYPE.ON_HOLD_FLOW].includes(comment.comment_type as any)
  
    && comment.comment_status && comment.plate_id === data.id);
  const isInfo = !!data.comments?.find(comment => comment.comment_type === COMMENT_TYPE.INFO
    && comment.comment_status && comment.plate_id === data.id);

  const orderId = data.order_id || data.id;
  const plateId = data.order_id ? data.id : undefined;
  return (
    <div className={classes.root}>
      <div>
        {data.types && data.types.map((type: PlateTypes) => (
          <Chip
            color="primary"
            key={type.plate_type}
            label={type.plate_type}
            avatar={<Avatar>{type.count}</Avatar>}
          />
        ))}
      </div>
      <div>
        <div className={classes.row}>
          <Typography variant="caption">Material</Typography>
          <Typography variant="body2">{name}</Typography>
        </div>
        <div className={classes.row}>
          <Typography variant="caption">Thickness</Typography>
          <Typography variant="body2">{thickness}</Typography>
        </div>
        <div className={classes.row}>
          <Typography variant="caption">Surface</Typography>
          <Typography variant="body2">{type}</Typography>
        </div>
        <div className={classes.row}>
          <Typography variant="caption">Finish Production</Typography>
          <div>
            <DataTableDate orderId={orderId} plateId={plateId} delivery_date={data.delivery_date} 
                           disable={delivery_date_disable} clearable={!plateId} small />
          </div>
        </div>
        <div className={classes.row}>
          <Typography variant="caption">Packing Method</Typography>
          <div>
            <DataTableDeliveryMethod orderId={orderId} plateId={plateId!} delivery_method={data.delivery_method} small />
          </div>
        </div>
        <div className={classes.button}>
          <PlateProceedDialog
            data={row}
            checkpoints={checkpoints}
            isInfo={isInfo}
            isOnHold={isOnHold}
            hasSubmitAll={data.current_stage === PLATE_TYPE.FIND}
          />
        </div>
      </div>
    </div>
  );
};
