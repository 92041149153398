import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';

import Layout from './components/Layout';
import { Dashboard } from './containers/Dashboard';
import { EnforceRefresh } from './containers/EnforceRefresh';
import { HolesOrdersTable } from './containers/HolesOrdersTable';
import { MeasurementsOrdersTable } from './containers/MeasurementsOrders/MeasurementsOrdersTable';
import { InternalControlOrdersTable } from './containers/InternalControlOrders/InternalControlOrdersTable';
import { PrepareOrdersTable } from './containers/PrepareOrders/PrepareOrdersTable';
import { FindOrdersTable } from './containers/FindOrdersTable';
import { FreightOrdersTable } from './containers/FreightOrders/FreightOrdersTable';
import { CutOrdersTable } from './containers/CutOrdersTable';
import { InstallationOrders } from './containers/InstallationOrders/InstallationOrders';
import { EdgePolishOrdersTable } from './containers/EdgePolishOrdersTable';
import { ManualFinishOrdersTable } from './containers/ManualFinishOrdersTable';
import { InstallSinkOrdersTable } from './containers/InstallSinkOrdersTable';
import { EndControlOrdersTable } from './containers/EndControlOrdersTable';
import { PackingDataTable } from './containers/PackingOrders/PackingDataTable';
import { StorageDataTable } from './containers/StorageOrders/StorageDataTable';
import { CompletedOrdersTable } from './containers/CompletedOrders/CompletedOrdersTable';
import { FaultOrdersTable } from './containers/FaultOrders/FaultOrdersTable';
import { DeviationOrdersTable } from './containers/DeviationOrders/DeviationOrdersTable';
import { ManageUsers } from './containers/ManageUsers';
import { Login } from './containers/Login';
import { useIsAdmin } from './containers/withIsAdmin';
import { DictionaryProvider } from './DictionaryProvider';
import { DataProvider } from './containers/DataProvider';
import { Calendar, CalendarView } from './containers/CalendarView/CalendarView';
import jwt from 'jsonwebtoken';
import { logout } from './redux/actions/accountActions';

export const Router = () => (
  <Layout>
    <EnforceRefresh>
      <DictionaryProvider>
        <DataProvider>
          <Switch>
            <PrivateRoute exact path="/" component={Dashboard} />
            <PrivateRoute exact path="/measurements" component={MeasurementsOrdersTable} />
            <PrivateRoute exact path="/internal_control" component={InternalControlOrdersTable} />
            <PrivateRoute disallowedRole="monter" exact path="/prepare" component={PrepareOrdersTable} />
            <PrivateRoute disallowedRole="monter" exact path="/find" component={FindOrdersTable} />
            <PrivateRoute disallowedRole="monter" exact path="/freight" component={FreightOrdersTable} />
            <PrivateRoute disallowedRole="monter" exact path="/cut" component={CutOrdersTable} />
            <PrivateRoute disallowedRole="monter" exact path="/edgepolish" component={EdgePolishOrdersTable} />
            <PrivateRoute disallowedRole="monter" exact path="/holes-cnc" component={HolesOrdersTable} />
            <PrivateRoute disallowedRole="monter" exact path="/manual-finish" component={ManualFinishOrdersTable} />
            <PrivateRoute disallowedRole="monter" exact path="/install-sink" component={InstallSinkOrdersTable} />
            <PrivateRoute disallowedRole="monter" exact path="/endcontrol" component={EndControlOrdersTable} />
            <PrivateRoute exact path="/packing" component={PackingDataTable} />
            <PrivateRoute exact path="/storage" component={StorageDataTable} />
            <PrivateRoute exact path="/installation" component={InstallationOrders} />

            <PrivateRoute exact path="/completed" component={CompletedOrdersTable} />
            <PrivateRoute exact path="/calendar" component={CalendarView} />
            <PrivateRoute exact path="/fault" component={FaultOrdersTable} />
            <PrivateRoute exact path="/deviation" component={DeviationOrdersTable} />

            <AdminRoute exact path="/manage-users" component={ManageUsers} />

            <Route exact path="/login" component={Login} />
          </Switch>
        </DataProvider>
      </DictionaryProvider>
    </EnforceRefresh>
  </Layout>
);

const mapStateToProps = (state) => ({
  isLoggedIn: state.account.isLoggedIn,
  isMonter: state.account.isMonter, ...state.account
});
const PrivateRoute = connect(mapStateToProps)(_privateRoute);

function _privateRoute ({ component: Component, disallowedRole, ...rest }) {
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (rest.isLoggedIn) {
      try {
        if (!rest.email) {
          dispatch(logout());
        }
      } catch (err) {
        dispatch(logout());
      }
    }
  }, []);

  if (!rest.isLoggedIn) {
    return (
      <Route {...rest} render={props => <Redirect
        to={{
          pathname: '/login',
          state: { from: props.location }
        }}
      />} />
    );
  }

  if (disallowedRole === 'monter' && rest.isMonter) {
    return (
      <Route {...rest} render={props => <Redirect
        to={{
          pathname: '/',
          state: { from: props.location }
        }}
      />} />
    );
  }

  return (
    <Route {...rest} render={props => <Component {...props} />} />
  );
}

function AdminRoute ({ component: Component, ...rest }) {
  const isAdmin = useIsAdmin();
  return (
    <Route
      {...rest}
      render={props => isAdmin ? <Component {...props} /> : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location }
          }}
        />
      )}
    />
  );
}
