import * as React from 'react';
import { Dialog, Fab, PropTypes, Checkbox, withMobileDialog, Theme } from '@material-ui/core';

import { Button } from '../Button';
import { throttled } from '../../services/helpers';
import { PreventPropagation } from '../PreventPropagation';

import { MyDialogActions } from './MyDialogActions';

import { makeStyles, useTheme } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { LockOrientationContext } from '../LockOrientation';
import { MyDialogContent } from './MyDialogContent';
import { MyDialogTitle } from './MyDialogTitle';

type MyDialogType = {
  dialogTitle: string,
  children: any,
  onCancel: () => void,
  onSubmit: () => void,
  onSubmitEnabled?: boolean,
  onSubmitDisabled?: boolean,
  onSubmitClose: boolean,
  onEnter?: () => void,
  color?: PropTypes.Color,
  customColor?: string,
  noTrigger?: boolean,
  buttonLabel?: string,
  variant?: 'text' | 'outlined' | 'contained',
  icon?: JSX.Element,
  custom?: JSX.Element,
  checked?: boolean,
  checkbox?: boolean,
  minHeight: number,
  maxHeight: number,
  fullScreen: boolean,
  triggerClassName?: string,
  size?: 'small' | 'medium' | 'large',
  modalSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false,
  submitButtonColor?: PropTypes.Color,
  submitButtonText?: string,
  cancelButtonText?: string,
  isStepper?: boolean,
  onOpen?: () => void,
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: '1 1 100%'
  },
  stepper: {
    paddingLeft: '15%',
    paddingRight: '15%'
  }
}));

const MyDialog: React.FC<MyDialogType> = ({
                    checkbox, checked, noTrigger, customColor,
                    buttonLabel, dialogTitle, onCancel, onSubmitEnabled, onSubmitDisabled, onSubmitClose,
                    onSubmit, onEnter, children, color, icon, size, modalSize,
                    triggerClassName, submitButtonColor, submitButtonText, cancelButtonText,
                    minHeight, maxHeight, fullScreen, custom, variant, isStepper, onOpen
                  }) => {
  const [isOpen, setOpen] = React.useState(false);
  const classes = useStyles();

  const theme = useTheme<Theme>();
  const isMediaDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const { setIsDesktopDevice, setIsMediumDevice } = React.useContext(LockOrientationContext);

  React.useEffect(() => {
    return () => resetScreens();
  }, []);

  const resetScreens = () => {
    setIsDesktopDevice(false);
    setIsMediumDevice(false);
  };

  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsDesktopDevice(isMediaDesktop);
    setIsMediumDevice(!isMediaDesktop);
    setOpen(true);
    
    if (onOpen) {
      onOpen();
    }
  };

  const onClose = () => {
    setOpen(false);
    resetScreens();
  };

  const handleOnClick = throttled(1000, onClick);

  // @ts-ignore
  const renderChildren = typeof children === 'function' ? children({ onClose, onClick: handleOnClick }) : children;

  const renderTrigger = (): any => noTrigger ? null : triggerIcon();

  const triggerIcon = () => {
    if (custom) {
      return (
        <div onClick={handleOnClick} style={{ marginRight: 4 }}>
          {custom}
        </div>
      );
    }

    if (checkbox) {
      return (
        <Checkbox
          checked={checked}
          disabled={!checked}
          onClick={handleOnClick}
        />
      );
    }

    if (icon) {
      return (
        <Fab aria-label={buttonLabel} onClick={handleOnClick} color={color} className={triggerClassName} size={size}
             classes={customColor ? { root: customColor } : {}}
        >
          {icon}
        </Fab>
      );
    }

    return (
      <Button
        variant={variant}
        aria-label="Approve"
        onClick={onClick}
        color={color}
        className={triggerClassName}
        customColor={customColor}
      >
        {buttonLabel}
      </Button>
    );
  };

  return (
    <PreventPropagation>
      {renderTrigger()}
      <Dialog
        fullScreen={fullScreen}
        maxWidth={modalSize}
        fullWidth
        open={isOpen}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        onEnter={onEnter}
        className={isStepper && isMediaDesktop ? classes.stepper : ''}
      >
        <MyDialogTitle>{dialogTitle}</MyDialogTitle>
        <MyDialogContent>
          <div className={isStepper ? '' : classes.root} style={{ minHeight, maxHeight }}>
            {renderChildren}
          </div>
        </MyDialogContent>
        <MyDialogActions
          submitButtonColor={submitButtonColor}
          submitButtonText={submitButtonText}
          onSubmitEnabled={onSubmitEnabled}
          onSubmitDisabled={onSubmitDisabled}
          onPress={async () => {
            await onSubmit();

            if (onSubmitClose) {
              onClose();
            }
          }}
          cancelButtonText={cancelButtonText}
          onCancel={async () => {
            await onCancel();
            onClose();
          }}
        />
      </Dialog>
    </PreventPropagation>
  );
}

const connected = withMobileDialog<MyDialogType>()(MyDialog as any) as any;
export { connected as MyDialog };

MyDialog.defaultProps = {
  onSubmitEnabled: true,
  onSubmitClose: true,
  onSubmit: () => ({}),
  onEnter: () => ({}),
  onCancel: () => ({}),
  onOpen: () => ({}),
  color: 'default',
  variant: 'contained',
  size: 'large',
  modalSize: 'md',
  minHeight: 0,
  maxHeight: 500
};
