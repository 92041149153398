import * as React from 'react';
import { useDispatch } from 'react-redux';

import { fetchComments } from "../redux/comments/CommentActions";
import { fetchMaterials } from "../redux/materials/MaterialActions";
import { fetchOrders } from "../redux/orders/OrderActions";
import { fetchFaults, fetchPlates } from '../redux/plates/PlateActions';
import { fetchPlateTypes } from "../redux/plateTypes/PlateTypeActions";
import { fetchConfigurations } from '../redux/AppConfigurationRedux';
import { getSellerTeams } from "../redux/actions/ordersActions";

type DataProviderType = {}

export const DataProvider: React.FunctionComponent<DataProviderType> = ({ children }) => {
  const dispatch = useDispatch();

  const fetchAppData = React.useCallback(async () => {
    await dispatch(fetchConfigurations());
    await dispatch(getSellerTeams());
    await Promise.all([dispatch(fetchOrders()), dispatch(fetchPlates())]);
    await Promise.all([dispatch(fetchFaults()), dispatch(fetchPlateTypes()), dispatch(fetchMaterials()), dispatch(fetchComments())]);
  }, []);

  React.useEffect(() => {
    fetchAppData();
  }, []);

  return <React.Fragment>{children}</React.Fragment>;
};
