import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

import { syncCutoutDetails } from '../../redux/actions/ordersActions';

import EditRow from './EditRow';

type CutoutsEditType = {
  orderNumber: number,
  cutouts: CutoutDetailsType[]
  setCutouts: (list: CutoutDetailsType[]) => void
  setStateSpecHasError: (state: boolean) => void
}

type CutoutDetailsType = {
  typeId: number,
  plateId: number,
  label: string,
  mode: number,
  plateNo: string,
  orderedBy: string,
  type: string,
  sink: string,
  dimension: string,
  createdAt: string,
  createdBy: string,
  status: string,
  orderedDate: string,
  comments: string
}

const useStyles = makeStyles(() => ({
  center: {
    justifyContent: 'center',
    textAlign: 'center',
    flex: '1 1 100%'
  },
  list: {
    listStyleType: 'none',
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: 1000,
    margin: '0 auto',
    justifyContent: 'center'
  },
  cutout: {
    width: 'calc(33.33% - 20px)',
    margin: 10,
    boxSizing: 'border-box',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 4,
    '&.mode2': {
      borderColor: '#d3ebc4'
    },
    '&.mode1': {
      borderColor: '#fcf8e3'
    },
    '&.mode0': {
      borderColor: '#337ab7'
    },
  },
  header: {
    padding: 10,
    '&.mode2': {
      backgroundColor: '#d3ebc4',
      color: '#3c763d'
    },
    '&.mode1': {
      backgroundColor: '#fcf8e3',
      color: '#8a6d3b'
    },
    '&.mode0': {
      backgroundColor: '#337ab7',
      color: '#fff'
    },
  },
  body: {
    padding: 10
  },
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1
  },
}));

export const CutoutsEdit = ({ orderNumber, cutouts, setCutouts, setStateSpecHasError }: CutoutsEditType) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { name: accountName, email: accountEmail } = useSelector((state: GlobalStoreType) => state.account);

  const [cutoutEditOpen, setCutoutEditOpen] = React.useState(false);
  const [editedCutout, setEditedCutout] = React.useState({} as CutoutDetailsType);

  const [cutoutDimension, setCutoutDimension] = React.useState("");
  const [cutoutStatus, setCutoutStatus] = React.useState("");
  const [cutoutComments, setCutoutComments] = React.useState("");

  const mainStatusOption = 'På Lager hos Scan Granitt';
  const statusOptions1 = [mainStatusOption, 'I Bestilling av Scan Granitt'];
  const statusOptions2 = [mainStatusOption, 'Hos Kunde', 'Fra Tegning/Mål'];
  const [statusOptions, setStatusOptions] = React.useState([] as string[]);

  const editCutout = (details: CutoutDetailsType) => {
    setEditedCutout(details);
    
    setCutoutDimension(details.dimension);
    setCutoutStatus(details.status);
    setCutoutComments(details.comments);
    
    setStatusOptions(details.orderedBy === 'Scan Granitt' ? statusOptions1 : statusOptions2);
    
    setCutoutEditOpen(true);
  }

  const submitChange = () => {
    const updatedCutouts = cutouts.map(cutout => {
      if (cutout === editedCutout) {
        cutout.createdBy = accountName;
        cutout.dimension = cutoutDimension;
        cutout.mode = (cutoutStatus === mainStatusOption) ? 2 : cutoutStatus ? 1 : 0;
        cutout.status = cutoutStatus;
        cutout.comments = cutoutComments;
      }
      return cutout;
    });

    setCutouts(updatedCutouts);
    setStateSpecHasError(!updatedCutouts.some(cutout => cutout.status));

    const syncData = {
      label: editedCutout.label,
      plateId: editedCutout.plateId,
      dimension: editedCutout.dimension,
      status: editedCutout.status,
      comments: editedCutout.comments
    }

    dispatch(syncCutoutDetails(orderNumber, syncData, accountEmail));

    setCutoutEditOpen(false);
  }


  if (!cutouts) {
    return null;
  }

  return (
    <React.Fragment>
      <Dialog
        maxWidth={'sm'}
        fullWidth
        open={cutoutEditOpen}
      >
        <DialogTitle>Edit cutout - {editedCutout.label} {editedCutout.plateNo}</DialogTitle>
        <DialogContent>
          <EditRow label='Bestiller' value={editedCutout.orderedBy} />
          <EditRow label='Utskjæringstype' value={`${editedCutout.label}-${editedCutout.type}`} />
          <EditRow label='Vask' value={editedCutout.sink} />
          {editedCutout.label !== "Vask" && (<EditRow label='Mål / Type' value={cutoutDimension} setValue={setCutoutDimension} />)}
          <EditRow label='Status' value={cutoutStatus} setValue={setCutoutStatus} options={statusOptions} />
          <EditRow label='Kommentarer' value={cutoutComments} setValue={setCutoutComments} multiline />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={() => setCutoutEditOpen(false)}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={() => submitChange()}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.center}>
        <ul className={classes.list}>
          {!cutouts && <strong>LOADING...</strong>}
          {cutouts.length === 0 && <strong>CUTOUTS NOT SET</strong>}
          {cutouts.map((cutout, index) => (
            <li key={index} className={classes.cutout + ' mode' + cutout.mode} onClick={() => editCutout(cutout)}>
              <div className={classes.header + ' mode' + cutout.mode}>
                <strong>{cutout.label} {cutout.plateNo} {cutout.mode ? '✔' : ''}</strong>
              </div>
              <div className={classes.body}>
                <strong>{cutout.createdAt}: {cutout.mode ? 'Registrert' : 'Ikke registrert'}</strong>
                <div>Registrert av: {cutout.createdBy}</div>
                <br />
                <div>Bestiller: {cutout.orderedBy}</div>
                {!!cutout.dimension && (<div>Mål / Type: {cutout.dimension}</div>)}
                {!!cutout.mode && (<div>Status: {cutout.status}</div>)}
                {!!cutout.orderedDate && (<div>Bestilt Dato: {cutout.orderedDate}</div>)}
                <div>Utskjæringstype: {cutout.type}</div>
                {!!cutout.sink && (<div>Vask: {cutout.sink}</div>)}
                {!!cutout.comments && (<div>Kommentarer: {cutout.comments}</div>)}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </React.Fragment>
  )
}
