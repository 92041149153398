import * as React from "react";
import { Typography } from "@material-ui/core";
import { red, green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/styles";
import classnames from "classnames";
import { PLATE_TYPE } from "../../shared/constants";

const useStyles = makeStyles(() => ({
  badge: {
    display: 'inline-grid',
    marginTop: '5px',
    borderRadius: 20,
    marginRight: '4px',
    padding: '4px',
    textAlign: 'center',
    lineHeight: 'normal',
    minWidth: '24px',
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "white",
    color: "white"
  },
  correct: {
    backgroundColor: green[500]
  },
  wrong: {
    backgroundColor: red[500]
  },
  completed: {
    borderColor: green[500],
    color: green[500],
    backgroundColor: "white"
  }
}));

const STAGE_SHORT = {
  PREPARE: "PR",
  FIND: "F",
  CUT: "C",
  EDGE_POLISH: "EP",
  HOLES: "H",
  MANUAL_FINISH: "MF",
  INSTALL_SINK: "IS",
  END_CONTROL: "EC",
  PACKING: "P",
  INSTALLATION: "IN",
  COMPLETED: "C"
}

export function EventBadge({plate: { current_stage, reference_number } }: any) {
  const classes = useStyles();
  const className = classnames({
    [classes.badge]: true,
    [classes.completed]: current_stage === PLATE_TYPE.COMPLETED,
    [classes.correct]: [PLATE_TYPE.INSTALLATION, PLATE_TYPE.PACKING].includes(current_stage),
    [classes.wrong]: ![PLATE_TYPE.INSTALLATION, PLATE_TYPE.PACKING, PLATE_TYPE.COMPLETED].includes(current_stage)
  });

  const stage = current_stage;

  //@ts-ignore
  const stageShort = STAGE_SHORT[stage];

  return (
    <Typography variant="button" className={className}>
      <span>{reference_number}</span>
      <span>{stageShort}</span>
    </Typography>
  );
}


