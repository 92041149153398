import * as React from 'react'
import { SetPlateHoles } from "../Plates/SetPlateHoles";
import { SPECIAL_COMPONENTS } from "../../shared/WizardStep/WizardEnums"
import { DatePicker } from "material-ui-pickers";
import { InlineDeliveryMethod } from "../Orders/InlineDeliveryMethod";
import { CutoutsEdit } from "./CutoutsEdit";
import { MyDialogCheckboxes } from "../Dialog/MyDialogCheckboxes";
import { PlateCheckbox } from "./PlateCheckbox";

type Props = {
  specialComponent: SPECIAL_COMPONENTS;
  getExtraData: (spec: SPECIAL_COMPONENTS) => any;
}

export const WizardSpecResolver: React.FC<Props> = ({ specialComponent, getExtraData }) => {
  if (!specialComponent || !getExtraData) {
    return null;
  }

  const extraData = getExtraData(specialComponent);

  switch (specialComponent) {
    case SPECIAL_COMPONENTS.CutoutsEdit:
      return (
        <CutoutsEdit 
          orderNumber={extraData.orderNumber}
          cutouts={extraData.cutouts}
          setCutouts={extraData.setCutouts}
          setStateSpecHasError={extraData.setStateSpecHasError}
        />
      )

    case SPECIAL_COMPONENTS.SetPlateHoles:
      const {
        dictionary,
        platesNumber,
        platesArray,
        platesArrayGroup,
        setPlates,
        setMultiSelect,
        onNoManualFinish,
        onNoEdgePolish,
        onEdgePolishOnCnc,
        onPlateNumberChange,
        setTypeCount
      } = extraData
      return (
        <SetPlateHoles
          onPlatesChange={setPlates}
          onMultiSelectChange={setMultiSelect}
          onNoEdgePolish={onNoEdgePolish}
          onNoManualFinish={onNoManualFinish}
          onEdgePolishOnCnc={onEdgePolishOnCnc}
          onPlateNumberChange={onPlateNumberChange}
          onTypeCountChange={setTypeCount}
          platesNumber={platesNumber}
          platesArray={platesArray}
          platesArrayGroup={platesArrayGroup}
          dictionary={dictionary || []}
        />
      )

    case SPECIAL_COMPONENTS.CheckMethodAndDate:
      const {
        selectedDate,
        submitDeliveryDate,
        selectedMethod,
        submitDeliveryMethod,
        deliveryMethods,
        checkpointsToShow,
        checkpointsState,
        handleCheckpointChange
      } = extraData
      return (
        <div>
          <DatePicker
            label="Finish Production"
            variant="outlined"
            value={selectedDate}
            onChange={submitDeliveryDate}
            error={!selectedDate}
            fullWidth
            format="dd-MM-yyyy"
            clearable
          />

          <InlineDeliveryMethod
            onChange={submitDeliveryMethod}
            dictionary={deliveryMethods}
            value={selectedMethod}
          />

          <MyDialogCheckboxes
            checkpoints={checkpointsToShow}
            selected={checkpointsState}
            onChange={handleCheckpointChange}
          />
        </div>
      );

    case SPECIAL_COMPONENTS.PlatesCheckbox:
      const {
        plates,
        onChangeCheckbox,
        isChecked: platesChecked,
        isDisabled: platesDisabled
      } = extraData

      return (
        <PlateCheckbox
          plates={plates}
          onChangeCheckbox={onChangeCheckbox}
          isChecked={platesChecked}
          isDisabled={platesDisabled}
        />
      )

    default:
      return null;
  }
}
