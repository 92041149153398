import * as React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { DatePicker } from 'material-ui-pickers';
import { Theme, Typography, Select, MenuItem } from '@material-ui/core';
import { ErrorMessage } from '../../components/Common/ErrorMessage';

import { MyDialog } from '../../components/Dialog/MyDialog';

import { MyDialogUser } from '../../components/Dialog/MyDialogUser';
import { useErrorHandler } from '../../hooks/useErrorHandler';
import { useUserInput } from '../../hooks/useUserInput';
import { pickupDate } from '../../redux/actions/ordersActions';
import { useIsAdmin, useIsMonter } from '../withIsAdmin';
import { formatToSaveDate, formatToDate, formatToTime, addBusinessDays } from '../../services/DateFormatter';

type FreightConfirmDateDialogType = {
  orderId: number,
  deliveryDate: Date
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 100%'
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    "& > div": {
      margin: theme.spacing(1)
    }
  },
  datePicker: {
    padding: '0 25%'
  }
}));

export function FreightConfirmDateDialog({ orderId, deliveryDate }: FreightConfirmDateDialogType) {
  const isAdmin = useIsAdmin();
  const isMonter = useIsMonter();
  const classes = useStyles();

  const { onUserChange, user, resetUser } = useUserInput();
  const { error, handleError, resetError, isUserError } = useErrorHandler();

  const transportCompanies = useSelector((state: GlobalStoreType) => state.dictionary.transportCompaniesDictionary);

  const [transportCompanyId, setTransportCompanyId] = React.useState();
  const onTransportCompanyChange = (e: any) => setTransportCompanyId(e.target.value);

  const [_date, changeDate] = React.useState(deliveryDate);

  const onChangeDate = async (newDate: Date) => changeDate(newDate);

  function resetLocalState() {
    resetUser();
  }

  async function submitPickupDate() {
    try {
      const date = formatToSaveDate(_date);

      const conditionalTime = formatToTime(_date);
      const daysAfter = conditionalTime < "12:00" ? 5 : 6;
      const minInstallationDate = addBusinessDays(_date, daysAfter);

      await pickupDate(orderId, { date, minInstallationDate, transportCompanyId, user_id: user });
      resetLocalState();
    } catch (err) {
      handleError(err);
      throw err;
    }
  }

  React.useEffect(() => {
    changeDate(deliveryDate);
  }, [deliveryDate]);

  React.useEffect(() => {
    resetError();
  }, [user]);

  if (!(isAdmin || isMonter)) {
    return null;
  }

  return (
    <MyDialog
      buttonLabel={formatToDate(_date)}
      dialogTitle="Date for pick up"
      color={'secondary'}
      variant={'outlined'}
      onSubmit={submitPickupDate}
      modalSize="xs"
    >
      <div className={classes.root}>
        <div className={classes.row}>
          <Typography variant="subtitle2">Choose transport company</Typography>
        </div>
        <div className={classes.row}>
          {transportCompanies?.length > 0 && (
              <Select
                value={transportCompanyId} 
                onChange={onTransportCompanyChange}
                fullWidth
              >
                {transportCompanies.map((company: DictionaryType) => (
                  <MenuItem key={company.value} value={company.value}>{company.text}</MenuItem>
                ))}
              </Select>
          )}
        </div>
        <br/>
        <div className={classes.row}>
          <Typography variant="subtitle2">Confirm date for pick up</Typography>
        </div>
        <div className={`${classes.row} ${classes.datePicker}`}>
          <DatePicker
            fullWidth
            variant="outlined"
            label="Date"
            value={_date}
            minDate={deliveryDate}
            onChange={onChangeDate}
            format="dd-MM-yyyy"
          />
        </div>
        <div className={classes.row}>
          <MyDialogUser onChange={onUserChange} userError={isUserError} user={user} />
        </div>
        <ErrorMessage message={error} isVisible={!!error} />
      </div>
    </MyDialog>
  );
}
