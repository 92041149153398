import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectPlateEntities } from '../../selectors/PlateSelectors';
import { sortInstallationRows } from '../MetaDataTable/MetaDataSortingFunctions';

import { MetaDataTable } from '../MetaDataTable/MetaDataTable';
import { selectDisplayNewOrderRow } from '../../redux/selectors';

export function InstallationOrders() {
  const orders: Order[] = useSelector(selectDisplayNewOrderRow());
  const plates: Plate[] = useSelector(selectPlateEntities);

  const ordersWithInstallation = orders
    .filter(order => order.is_installation)
    .map(order => ({
      ...order,
      plates: plates.filter(plate => plate.order_id === order.id)
    }));

  return (
    <MetaDataTable
      title="Installation"
      actionType="installation"
      rows={sortInstallationRows(ordersWithInstallation)}
      disableSorting
      addExtraInfo
    />
  );
}
