import * as React from 'react'
import { WarningInfo } from '../WarningInfo';

type Props = {
  isWarning: boolean
}


export const WizardStepHeaderTitle: React.FC<React.PropsWithChildren<Props>> = ({ children, isWarning }) => {
  if (isWarning) {
    return <WarningInfo>{children}</WarningInfo>
  } else {
    return <div>{children}</div>
  }
}
