import React, { useEffect, useState, useRef } from 'react';
import { Theme, Typography } from '@material-ui/core';
import Select, { components, ValueContainerProps } from 'react-select';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { setMeasuringTeams, setInstallationTeams } from "../../redux/actions/ordersActions";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    marginLeft: theme.spacing(1),
    position: 'absolute',
    zIndex: 2
  },
  contents: {
    display: 'contents'
  },
  fixedWidth: {
    width: 300
  },
  autoWidth: {
    width: 'auto',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}));

export class SelectTeamOption {
  value: string;
  label: string;

  constructor(_id: string, _name: string) {
    this.value = _id;
    this.label = _name;
  }
}

const ValueContainer = ({ children, ...props }: ValueContainerProps<any>) => {
  const { getValue, hasValue } = props;
  const nbValues = getValue().length;
  
  if (!hasValue) {
    return (
      <components.ValueContainer {...props}>
        {children}
      </components.ValueContainer>
    );
  }
  return (
    <components.ValueContainer {...props}>
      {`${nbValues} items selected`}
    </components.ValueContainer>
  );
};

export const FilterOrders = () => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const [parentWidth, setParentWidth] = useState<number>(window.innerWidth);
  const parentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleResize = () => {
      if (parentRef.current) {
        setParentWidth(parentRef.current.parentElement?.offsetWidth || window.innerWidth); // Now this works correctly
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isNarrowPanel = parentWidth < 600;

  const sellerTeams = useSelector((state: GlobalStoreType) => state.sellerTeams);

  const optMeasuringTeams = sellerTeams.allMeasuringTeams.map(team => new SelectTeamOption(team._id, team.name));
  const optInstallationTeams = sellerTeams.allInstallationTeams.map(team => new SelectTeamOption(team._id, team.name)); 

  const selMeasuringTeams = optMeasuringTeams.filter(team => sellerTeams.selMeasuringTeams.includes(team.value));
  const selInstallationTeams = optInstallationTeams.filter(team => sellerTeams.selInstallationTeams.includes(team.value)); 
    
  const [selectedMeasuringTeams, setSelectedMeasuringTeams] = React.useState<SelectTeamOption[]>(selMeasuringTeams);
  const [selectedInstallationTeams, setSelectedInstallationTeams] = React.useState<SelectTeamOption[]>(selInstallationTeams);

  React.useEffect(() => {
    const measuringTeams = selectedMeasuringTeams.map(item => item.value);

    dispatch(setMeasuringTeams(measuringTeams));
  }, [selectedMeasuringTeams]);

  React.useEffect(() => {
    const installationTeams = selectedInstallationTeams.map(item => item.value);

    dispatch(setInstallationTeams(installationTeams));
  }, [selectedInstallationTeams]);

  const components = { ValueContainer };

  return (
    <div ref={parentRef} className={`${styles.root} ${isNarrowPanel ? styles.contents : ''}`}>
      <div className={`${isNarrowPanel ? styles.autoWidth : styles.fixedWidth}`}>
		    <Typography variant="caption">Measuring team</Typography>
		    <Typography variant="body2" component="div">
          <Select
            components={components}
					  options={optMeasuringTeams}
					  onChange={(selected) => setSelectedMeasuringTeams([...selected])}
            value={selMeasuringTeams}
            hideSelectedOptions={false}
					  isMulti 
					/>
		    </Typography>
		  </div>
      <div className={`${isNarrowPanel ? styles.autoWidth : styles.fixedWidth}`}>
			  <Typography variant="caption">Installation team</Typography>
			  <Typography variant="body2" component="div">
          <Select
            components={components}
					  options={optInstallationTeams}
					  onChange={(selected) => setSelectedInstallationTeams([...selected])}
            value={selInstallationTeams}
            hideSelectedOptions={false}
					  isMulti 
					/>
			  </Typography>
		  </div>
    </div>  
  );
};
