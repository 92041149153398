import * as React from 'react';
import { Typography } from '@material-ui/core';
import { WarningInfo } from '../../components/WarningInfo';

import { FreightDialog } from '../FreightOrders/FreightDialog';
import { InstallationCompleteOrder } from '../InstallationOrders/InstallationCompleteOrder';
import { PlateProceedDialog } from '../PlatesTableOrders/PlateProceedDialog';
import { MeasurementsDialog } from '../MeasurementsOrders/MeasurementsDialog';
import { InternalControlDialog } from '../InternalControlOrders/InternalControlDialog';
import { PrepareDialog } from '../PrepareOrders/PrepareDialog';

import { COMMENT_TYPE, PLATE_TYPE } from '../../shared/constants';

type ActionOrderDataType = {
  actionType: string,
  checkpoints: CheckpointType[],
  data: Order
}

type ActionPlateDataType = {
  actionType: string,
  checkpoints: CheckpointType[],
  data: Plate,
  stageTitle?: string
}

type ExtraActionDataType = ActionOrderDataType & ActionPlateDataType

export const getActionType = ({ actionType, checkpoints, data, stageTitle }: ExtraActionDataType) => {
  switch (actionType) {
    case 'measurement':
      return <MeasurementsDialog data={data}  />;

    case 'internal_control':
      if (!data.cutouts) {
        return null;
      }

      return <InternalControlDialog checkpoints={checkpoints} data={data} />;

    case 'prepare':
      return <PrepareDialog checkpoints={checkpoints} data={data} />;

    case 'plate':
      if (stageTitle === PLATE_TYPE.PACKING) {
        if (data.current_stage !== PLATE_TYPE.PACKING) {
          return (
            <div style={{ textAlign: 'right' }}>
              <Typography variant="caption">Stage</Typography>
              <Typography variant="body1">{data.current_stage}</Typography>
            </div>
          )
        }
      }
      
      const isOnHoldPlate = !!data.comments?.find(comment => [COMMENT_TYPE.ON_HOLD, COMMENT_TYPE.ON_HOLD_FLOW].includes(comment.comment_type as any)
        && comment.comment_status && comment.plate_id === data.id);
      const isInfoPlate = !!data.comments?.find(comment => comment.comment_type === COMMENT_TYPE.INFO
        && comment.comment_status && comment.plate_id === data.id);

      return (
        <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <PlateProceedDialog
            data={data}
            isOnHold={isOnHoldPlate}
            isInfo={isInfoPlate}
            hasSubmitAll={data.current_stage === PLATE_TYPE.FIND}
            checkpoints={checkpoints}
          />
        </div>
      );

    case 'freight':
      if (data.is_freight) {
        return (
          <FreightDialog data={data} plates={data.plates!} />
        );
      }

      return null;

    case 'installation':
      if (data.is_installation) {
        const isInfoInstallation = false;
        return (
          <InstallationCompleteOrder
            orderId={data.id}
            reference_number={data.reference_number}
            plates={data.plates!}
            files={data.files}
            isInfo={isInfoInstallation}
            prepare_date={data.prepare_date}
            pickup_date={data.pickup_date}
            delivery_date={data.delivery_date}
            installation_date={data.installation_date}
            installation_confirmed={data.installation_confirmed}
          />
        );
      }

      return null;

    default:
      return null;
  }
};

