import * as dictionaryConstants from "../constants/dictionaryConstants";

const INITIAL_STATE = {
  measurementsCheckpoints: [],
  measurementsExtraCheckpoints: [],
  internalControlCheckpoints: [],
  prepareCheckpoints: [],
  findCheckpoints: [],
  installationCheckpoints: [],

  deliveryMethodsDictionary: [],
  affectPriceOptionsDictionary: []
};

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case dictionaryConstants.GET_DICTIONARIES_SUCCESS:
      return {
        ...state,
        measurementsCheckpoints: action.payload.measurement,
        measurementsExtraCheckpoints: action.payload.measurement_ex,
        internalControlCheckpoints: action.payload.internal_control,
        prepareCheckpoints: action.payload.prepare,
        findCheckpoints: action.payload.find,
        cutCheckpoints: action.payload.cut,
        edgePolishCheckpoints: action.payload.edge_polish,
        endControlCheckpoints: action.payload.end_control,
        holesCheckpoints: action.payload.holes,
        manualFinishCheckpoints: action.payload.manual_finish,
        installSinkCheckpoints: action.payload.install_sink,
        packingCheckpoints: action.payload.packing,
        installationCheckpoints: action.payload.installation,

        stabDetailDictionary: action.payload.stab_detail,
        faultReasonDictionary: action.payload.fault_reason,
        deliveryMethodsDictionary: action.payload.delivery_method,
        affectPriceOptionsDictionary: action.payload.affect_price_options,
        transportCompaniesDictionary: action.payload.transport_companies,
        packingIbsgDictionary: action.payload.packingibsg,
        packingDbsgDictionary: action.payload.packingdbsg,
        packingSbtcDictionary: action.payload.packingsbtc,
        packingPubcDictionary: action.payload.packingpubc,
      };

    default:
      return state;
  }
};
