export enum STAGE {
    BOOL = "Boolean question",
    TEXT = "Text field",
    FILE = "File upload",
    SPEC = "Special",
    WARN = "Warning",
    NONE = "None"
  }

  export enum WizardStateEnum {
    UNSET = -1,
    DISABLED = 0,
    NOT_DONE = 1,
    COMPLETED = 2
  }
  
  export enum SPECIAL_COMPONENTS {
    CutoutsEdit = 'CutoutsEdit',
  
    SetPlateHoles = 'SetPlateHoles',
    CheckMethodAndDate = 'CheckMethodAndDate',
  
    PlatesCheckbox = 'PlatesCheckbox',
  }
  